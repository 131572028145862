import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import TabsComponent from '../../../SharedComponents/Tabs/Tabs';
import { ProjectTabList } from '../../../../constants/TabList';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import logo from '../../../../assets/logo.png';
import './ProjectDetails.scss';
import ProjectDetailsTabContent from './ProjectDetailsTabContent';
import ExecutivesTabContent from './ExecutivesTabContent';
import TalentTabContent from './TalentTabContent';
import DealsTabContent from './DealsTabContent';
import WGATabContent from './WGATabContent';
import MiscFiles from '../../../MiscFiles/MiscFiles';
import DraftsSection from './DraftsSection';
import { withUserContext } from '../../../../contexts/UserContext';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import MessageModal from '../../../SharedComponents/MessageModal';
import MenuField from '../../../SharedComponents/MenuField/MenuField';
import Tooltip from '@material-ui/core/Tooltip';
import { projectMenuOptions, projectQueueInitialJson } from './Config';
import Loader from '../../../../Common/Loader/Loader';
import { filePayload } from '../../../MiscFiles/config';
import moment from 'moment';
import WGAContainer from '../Dialog/WGA/WGAContainer';

export default withUserContext(class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            projectId: null,
            isFetchingProjectDetails: false,
            showSuccessModal: false,
            showFailureModal: false,
            deleteSuccessMessage: "Record deleted successfully!",
            deleteFailureMesaage: "Deletion Failed!",
            copySuccessMessage: "Record copied successfully!",
            copyFailureMesaage: "Copying Failed!",
            addQueueSuccessMessage: "Project added to queue!",
            removeQueueMessage: "Project removed from queue!",
            addQueueFailureMessage: "Failed to add project to queue!",
            removeQueueFailureMessage: "Failed to remove project from queue!",
            accessDeniedMessage: "You dont have access to view this project and it's related entities!",
            projectDoesntExistMessage: "This project does not exist!",
            doesntExistTitle: "Doesnt Exist",
            saveTitle: "Success",
            failTitle: "Failure",
            accessDeniedTitle: "Access Denied",
            doesThisProjectExist: true,
            showAccessDenied: false,
            mode: '',
            fileCount: 0,
            drafts: null,
            isFetchingDrafts: false,
            isFetchingReport: false,
            anchorEl: null,
            projectQueueInitialJson: JSON.parse(JSON.stringify({ ...projectQueueInitialJson })),
            isManagingQueue: false,
            isDeletingProject: false,
            projectRefreshPage: false,
            tenant_id: null,
            file: null,
            projectMenuOptions: JSON.parse(JSON.stringify([...projectMenuOptions])),
            projectTabList: JSON.parse(JSON.stringify([...ProjectTabList])),
            getProjectDetails: (projectId) => this.getProjectDetails(projectId),
            getPoster: (projectId) => this.getPoster(projectId),
            showRecordCannotbeDeleted: false,
            isFetchingProjectReport: false,
            showWGAformModal: false,
            wgaId:null,
            isPosting:null,
            divisionId:null,
            // projectId :null,
            projectDetails:{},
            deletedWgaID:null
            
        }
        this.fileRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if ((props.refreshPage?.value !== state.projectRefreshPage) && props.refreshPage?.modal === 'project') {
            if (props.refreshPage?.value) {
                state.getPoster(state.projectId)
                state.getProjectDetails(state.projectId);
                props.toggleRefreshPage(props.refreshPage?.modal)
            }
            return {
                projectRefreshPage: props.refreshPage?.value || false
            }
        }
        if ((props?.userContext?.active_tenant?.tenant_id !== state.tenant_id) && state.projectId) {
            state.getPoster(state.projectId)
            state.getProjectDetails(state.projectId);
            return {
                tenant_id: props?.userContext?.active_tenant?.tenant_id
            }
        }
        return null;
    }

    componentDidMount() {
        this.setState({ projectId: this.props.match?.params?.id, projectRefreshPage: this.props.refreshPage?.value })
    }

    handleQueue = (val, mode) => {
        this.setState({ isManagingQueue: true, mode: mode })
        let projectQueue = JSON.parse(JSON.stringify({ ...this.state.projectQueueInitialJson }));
        projectQueue.project_id = this.state.projectId;
        projectQueue.user_id = this.props?.userContext?.user_profile?.user_id;
        projectQueue.is_delete = (mode === 'queueRemove') ? 1 : 0;
        console.log("projectQueue", projectQueue)
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + "/userQueue", projectQueue,
            this.props.userContext.active_tenant.tenant_id)
            .then(
                (response) => {
                    console.log("response delete", response)
                    this.setState({ isManagingQueue: false, mode: mode })
                    if (response?.data?.error) {
                        this.manageMessageModal('Fail', true, mode)
                    } else {
                        this.manageMessageModal('Success', true, mode);
                        this.getPoster(this.state.projectId)
                        this.getProjectDetails(this.state.projectId)
                    }
                },
                (err) => {
                    console.log("error", err);
                    this.setState({ isManagingQueue: false, mode: mode })
                    this.manageMessageModal('Fail', true, mode)
                }
            );
    }

    getFiles() {
        // let canAddRemoveViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateProjectFiles === true ? true : false
        let canAddPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateProjectFiles === true ? true : false
        let canViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateProjectFiles === true ? true : false
        let canRemovePrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateProjectFiles === true ? true : false
        let payload = {
            ...filePayload,
            projectId: this.state?.projectId,
            type: "project",
            module: 'project',
            subModule: 'misc',
            tenantName: this.props?.userContext?.active_tenant?.tenant_name,
            // isPrivate:canAddRemoveViewPrivateProjectFiles?1:0
            isPrivate:canAddPrivateProjectFiles || canViewPrivateProjectFiles || canRemovePrivateProjectFiles ?1:0
        }
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log(response);
                if (response.data?.error) {
                    console.log("Error from fetch file", response.data?.error)
                    return;
                }
                let count = response.data?.Contents?.length;
                this.setState({ fileCount: count })
            }, err => {
                console.log('from misc files', err)
            })
    }
    getCommaSeparatedValues = (field, list) => {
        let formattedValue = "";
        if (typeof list === 'string') {
            formattedValue = list;
        } else {
            list?.map(item => {
                if (!(item?.end_date && this.formatDate(item?.end_date) <= this.formatDate(new Date()))) {
                    item[field] ? (formattedValue = formattedValue + (formattedValue ? ", " : " ") + item[field]) : null
                    return item
                }
            })
            // list?.map(item => item[field] ? (formattedValue = formattedValue + (formattedValue ? ", " : " ") + item[field]) : null);
        }
        return formattedValue;
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    modifyMenuOptions = () => {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        let projectOptions = JSON.parse(JSON.stringify([...projectMenuOptions]))
        if (!permissions?.canDeleteProject) {
            projectOptions = projectOptions.filter(item => item.value !== 4)
        }
        if (this.state.projectDetails?.is_add_to_queue === 1) {
            projectOptions = projectOptions.filter(item => item.value !== 1)
        } 
        // else {
        //     projectOptions = projectOptions.filter(item => item.value !== 2)
        // }
        this.setState({ projectMenuOptions: projectOptions })
    }

    modifyTabOptions = () => {
        let projectTabList = JSON.parse(JSON.stringify([...this.state.projectTabList]))
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        if (!permissions?.canViewOrModifyProjectWga) {
            projectTabList = projectTabList.filter(item => item.value !== 5)
        }
        if (!permissions?.canViewProjectDeals) {
            projectTabList = projectTabList.filter(item => item.value !== 4)
        }
        if (!permissions?.canViewTalentRepAgencies) {
            projectTabList = projectTabList.filter(item => item.value !== 3)
        }
        this.setState({ projectTabList: projectTabList })
    }

    getProjectDetails = (projectId) => {
        projectId = projectId ? projectId : this.props.match?.params?.id;
        this.setState({ isFetchingProjectDetails: true, tenant_id: this.props?.userContext?.active_tenant?.tenant_id, projectId: projectId });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/project?projectId=${projectId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.getFiles();
                // this.fetchDrafts();
                this.modifyTabOptions();
                this.setState({
                    isFetchingProjectDetails: false,
                    showAccessDenied: response.data[0]?.is_view_project === 1 ? false : true,
                    projectDetails: response.data[0],
                    doesThisProjectExist: (!response.data[0] && response?.config?.headers?.tenantid) ? false : true
                }, () => {
                    this.modifyMenuOptions();
                    this.fetchDrafts();
                });
            },
                (err) => {
                    this.setState({ isFetchingProjectDetails: false })
                })
    }

    deleteProject = (projectDetails) => {
        this.setState({ isDeletingProject: true, mode: 'delete' })
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/project', projectDetails,
            this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.setState({
                        isDeletingProject: false,
                        showRecordCannotbeDeleted: true
                    })
                } else {
                    this.setState({ isDeletingProject: false })
                    if (!response.data.error) {
                        this.manageMessageModal('Success', true, 'delete_project');
                        // this.props.history.push("/")
                    }
                }

            },
                (err) => {
                    this.setState({ isDeletingProject: false })
                    console.log("Post project details error: " + err);
                });
    }

    fetchDrafts = () => {
        this.setState({ isFetchingDrafts: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/projectEntity?projectId=${this.state.projectId}&entity=DRAFTS`,
            this.props?.userContext?.active_tenant?.tenant_id, this.state.projectDetails?.division_id)
            .then(response => {
                let drafts = response.data;
                drafts?.sort(function (a, b) {
                    return new Date(b.received_date) - new Date(a.received_date);
                });
                this.setState({ isFetchingDrafts: false, drafts: drafts });
            },
                (err) => {
                    this.setState({ isFetchingDrafts: false })
                })
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    getTabComponent = () => {
        // let canAddRemoveViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateProjectFiles === true ? true : false
        let canAddPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateProjectFiles === true ? true : false
        let canViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateProjectFiles === true ? true : false
        let canRemovePrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateProjectFiles === true ? true : false
        switch (this.state.tabValue) {
            case 1: return <ProjectDetailsTabContent
                projectDetails={this.state.projectDetails || {}}
                fileCount={this.state.fileCount || 0}
                isLoading={this.state.isFetchingProjectDetails || false} />
            case 2: return <ExecutivesTabContent
                projectId={this.state.projectId}
                manageMessageModal={this.manageMessageModal}
                refreshPage={this.props.refreshPage}
                permissions={this.props?.userContext?.active_tenant?.permissions}
                toggleRefreshPage={this.props.toggleRefreshPage}
                divisionId={this.state.projectDetails?.division_id}
                editCallback={(id, item) => this.handleNavigation(id, null, item)} />
            case 3: return <TalentTabContent
                projectId={this.state.projectId}
                permissions={this.props?.userContext?.active_tenant?.permissions}
                handleShowTalentPopup={this.props.handleShowTalentPopup}
                handleShowRepresentativePopup={this.props.handleShowRepresentativePopup}
                handleShowAgencyPopup={this.props.handleShowAgencyPopup}
                manageMessageModal={this.manageMessageModal}
                refreshPage={this.props.refreshPage}
                toggleRefreshPage={this.props.toggleRefreshPage}
                divisionId={this.state.projectDetails?.division_id}
                editCallback={(id, item) => this.handleNavigation(id, null, item)} />
            case 4: return <DealsTabContent
                refreshPage={this.props.refreshPage}
                userContext={this.props?.userContext}
                toggleRefreshPage={this.props.toggleRefreshPage}
                projectId={this.state.projectId} 
                divisionId={this.state.projectDetails?.division_id} />
            case 5: return <WGATabContent
                projectId={this.state.projectId}
                refreshPage={this.props.refreshPage}
                permissions={this.props?.userContext?.active_tenant?.permissions}
                manageMessageModal={this.manageMessageModal}
                toggleRefreshPage={this.props.toggleRefreshPage}
                divisionId={this.state.projectDetails?.division_id}
                editCallback={(id, item) => this.handleNavigation(id, null, item)} 
                deletewgaId={this.deletewgaId}/>
            case 6: return <MiscFiles
                fileRef={this.fileRef}
                toggleRefreshPage={this.props.toggleRefreshPage}
                // canModifyFiles={true}
                canModifyFiles={canRemovePrivateProjectFiles}
                // showPrivateCheck={canAddRemoveViewPrivateProjectFiles}
                showPrivateCheck={canAddPrivateProjectFiles}
                viewPrivateFiles={canViewPrivateProjectFiles}
                divisionId={this.state.projectDetails?.division_id}
                // filePermission={
                //     (this.props?.userContext?.user_profile?.group_name === 'Feature Story Admin' ||
                //         this.props?.userContext?.user_profile?.user_id === this.state.projectDetails?.created_by
                //     )
                // }
                filePayload={{
                    ...filePayload,
                    projectId: this.state?.projectId,
                    type: "project",
                    module: 'project',
                    subModule: 'misc'
                }}
                isConfidential={this.state.projectDetails?.is_confidential}
            />
        }
    }

    handleText = () => {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        switch (this.state.tabValue) {
            case 1: return permissions?.canModifyProject ? "Edit" : null
            case 2: return permissions?.canModifyProject ? "Add Executive" : null
            case 3: return permissions?.canModifyProject ? "Add Talent" : null
            case 4: return permissions?.canModifyDeal ? "Create Deal" : null
            case 5: return permissions?.canViewOrModifyProjectWga ? "New WGA" : null
            // case 6: return (permissions?.canModifyProject || permissions?.canAddRemoveViewPrivateProjectFiles) ? "Add File" : null
            case 6: return permissions?.canModifyProject ? "Add File" : null
        }
    }

    handleIcon = () => {
        switch (this.state.tabValue) {
            case 1: return "pen"
            case 2: return "plus-circle"
            case 3: return "plus-circle"
            case 4: return "plus-circle"
            case 5: return "plus-circle"
            case 6: return "plus-circle"
        }
    }

    handleProjectDeletion = () => {
        this.setState({ showDeleteConfirmationModal: true });
    }
    
    handleProjectDeleteConfirm = () => {
        let projectDetails = JSON.parse(JSON.stringify({ ...this.state.projectDetails }));
        projectDetails.is_delete = 1;
        this.deleteProject(projectDetails);
    }

    handleProjectReport = () => {
        let json = {
            "where_query": `(project.project_id= ${this.state?.projectId})`,
            "from_query": {"projects":1,"project_genres":1,"project_executives":1,"project_source_material":1,"project_talent":1,"projects_ba_executive":0,"projects_le_executive":0,"wga":0,"authors":0,"submissions":1,"submission_author":1,"submission_elements":0,"submission_format":0,"submission_source_material":1,"submission_submitted":0,"submission_submitted_by":0,"submission_compare_to":0,"coverages":1,"coverages_analyst":0,"coverages_workflow":0,"weekend_reads":0,"weekend_read_assigned_to":0,"weekend_read_exec_read":0,"agency":0,"representative":0,"deals":1,"deal_ammendments":0,"deal_steps":1,"talent":0,"talent_email":0,"talent_role":0,"drafts":0},
            "order_by_clause": 'ORDER BY ' + 'project_title' + ' ' + 'ASC',
            "project_id": this.state?.projectId
        };
        let load = {
            "payload": 'generateReport',
            "reportName": 'rptCnProjectSingleTitle',
            "searchJson": btoa(JSON.stringify(json)),
            "showHtml": 0,
            "isStaticReport": 0,
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            "divisionId": this.state.projectDetails?.division_id
        }
        this.setState({ isFetchingProjectReport: true, mode: "pdf" })
        ScripterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200) {
                        ScripterService.downloadFile(response.body).then(res => {
                            const url = window.URL.createObjectURL(res.data);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'Project Report')
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ isFetchingProjectReport: false })
                        }, (err) => {
                            console.log("File Download Failed:" + err);
                            this.setState({ isFetchingProjectReport: false })
                        });
                    } else {
                        this.setState({ isFetchingProjectReport: false })
                    }
                }
            })
    }

    handleExportToPdf = () => {
        this.setState({ isFetchingReport: true, mode: "pdf" })
        let json = { "project_id": this.state?.projectId };
        let load = {
            "payload": 'generateReport',
            "reportName": 'rptCnProjectDetail',
            "searchJson": btoa(JSON.stringify(json)),
            "showHtml": 0,
            "isStaticReport": 0,
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            "divisionId": this.state.projectDetails?.division_id
        }
        ScripterService.getDataWS(load)
        .then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if(response?.statusCode == 200) {
                    this.setState({ isFetchingReport: false, mode: "" }) 
                    ScripterService.downloadFile(response.body).then(res => {
                        const url = window.URL.createObjectURL(res.data);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ProjectDetails');
                        document.body.appendChild(link);
                        link.click();
                    }, (err) => {
                        console.log("File Download Failed:" + err);
                        this.setState({ isFetchingReport: false, mode: "" }) 
                    });
                } else {
                    this.setState({ isFetchingReport: false, mode: "" }) 
                }
            }
        })
    }

    // handleExportToPdf = () => {
    //     this.setState({ isFetchingReport: true, mode: "pdf" })
    //     let postJson = { project_id: this.state?.projectId };
    //     ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/generateReport?reportName=${"rptCnProjectDetail"}`, postJson,
    //         this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id, '&searchJson=')
    //         .then(response => {
    //             console.log("first", response)
    //             this.setState({ isFetchingReport: false, mode: "" })
    //             if (response?.data?.error) {
    //                 alert(response?.data?.message)
    //                 //Report template not found 404
    //             } else {
    //                 ScripterService.downloadFile(response.data).then(response => {
    //                     console.log("second", response)
    //                     const url = window.URL.createObjectURL(response.data);
    //                     const link = document.createElement('a');
    //                     link.href = url;
    //                     link.setAttribute('download', 'ProjectDetails');
    //                     document.body.appendChild(link);
    //                     link.click();
    //                 }, (err) => {
    //                     console.log("File Download Failed:" + err);
    //                 });
    //             }
    //         },
    //             (err) => {
    //                 this.setState({ isFetchingReport: false, mode: null })
    //             })

    // }
    showWGAformModal= (wgaId = null, projectId, divisionId) => {
        this.setState({ showWGAformModal: true, wgaId: wgaId, projectId: projectId, divisionId: divisionId })
    }
    handleNavigation = (id = null, file, details) => {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        switch (this.state.tabValue) {
            case 1: permissions?.canModifyProject && this.props.showNewProjectModal(this.state.projectId); break;
            case 2: (id ? permissions?.canEditTalentRepAgencies : permissions?.canCreateTalentRepsAgency) && 
                this.props.showAddExecutiveModal(id, this.state.projectId, details, this.state.projectDetails?.division_id, this.getProjectDetails); break;
            case 3: (id ? permissions?.canEditTalentRepAgencies : permissions?.canCreateTalentRepsAgency) && this.props.showAddTalentModal(id, this.state.projectId, details, this.state.projectDetails?.division_id); break;
            case 4: permissions?.canModifyDeal && this.props.showNewDealProject(); break;
            case 5: permissions?.canViewOrModifyProjectWga && this.showWGAformModal(id, this.state.projectId, this.state.projectDetails?.division_id); break;
            case 6: this.fileRef.current.onFileUpload(file); break;
        }
    }

    onClickCallback = (val) => {
        if (val) {
            switch (val?.value) {
                case 1: this.handleQueue(val, 'queueAdd'); break;
                case 2: this.handleQueue(val, 'queueRemove'); break;
                case 3: this.handleExportToPdf(); break;
                case 4: this.handleProjectDeletion(); break;
            }
        }
    }

    manageMessageModal = (mode, value, operation) => {
        switch (mode) {
            case 'Success': this.setState({ showSuccessModal: value, showFailureModal: false, mode: operation })
                break;
            case 'Fail': this.setState({ showSuccessModal: false, showFailureModal: value, mode: operation })
                break;
        }
    }

    handleProjectDetailsMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    closeMessageModal = () => {
        this.setState({ showSuccessModal: false, showFailureModal: false, mode: null });
        if (this.state.showAccessDenied || !this.state.doesThisProjectExist) {
            this.setState({ showAccessDenied: false, doesThisProjectExist: true }, () => {
                this.props.history.push("/")
            })
        }
        if (this.state.mode === 'delete_project') {
            this.props.history.push("/")
        }
    }

    getPoster(projectId) {
        projectId = projectId ? projectId : this.props.match?.params?.id;
        let payload = JSON.parse(JSON.stringify({ ...filePayload }));
        payload.tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        payload.projectId = projectId;
        payload.module = "project";
        payload.subModule = "poster";
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("getFile!!!!", response);
                // if (response.data?.error) {
                //     console.log("Error from fetch file", response.data?.error)
                //     return;
                // }
                let formattedList = response.data?.Contents?.map((file) => {
                    return {
                        "name": file.Key.replace(file.Prefix + "/", ""),
                        "path": file.Prefix,
                        "url": file.url
                    }
                })
                this.setState({ file: formattedList[0] })
            }, err => {
                console.log('from misc files', err)
            })
    }

    fieldChanged = (value, mode = "error", refreshValue = null, operation = null) => {
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value, refreshPageValue: refreshValue, mode: operation });
            case "fail": return this.setState({ showFail: value });
        }
    }
    wgaNameChanged = (value) => {
        this.setState({ wgaTypeName: value })
    }

    wgaIdChanged = (value) => {
        this.setState({ wgaId: value, mode : "Edit" })
    }

    deletewgaId = (value) => {
        if(value==this.state.wgaId){
        this.handleClose();
        }
    }

    // toggleRefreshPage = (modalName) => {
    //     let refreshPage = {
    //         "modal": modalName,
    //         "value": !this.state.refreshPage?.value
    //     }
    //     this.setState({ refreshPage: refreshPage })
    //}
    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }
    handleClose=() => this.setState({ showWGAformModal: false, wgaId: null });

    render() {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        let message = (this.state.mode === 'delete_project' || this.state.mode === 'delete') ?
            (this.state.showSuccessModal ? this.state.deleteSuccessMessage : this.state.deleteFailureMesaage) :
            this.state.mode === 'copy' ?
                (this.state.showSuccessModal ? this.state.copySuccessMessage : this.state.copyFailureMesaage) :
                this.state.mode === 'queueAdd' ?
                    (this.state.showSuccessModal ? this.state.addQueueSuccessMessage : this.state.addQueueFailureMessage) :
                    this.state.mode === 'queueRemove' ?
                        (this.state.showSuccessModal ? this.state.removeQueueMessage : this.state.removeQueueFailureMessage) :
                        this.state.showAccessDenied ? this.state.accessDeniedMessage :
                            !this.state.doesThisProjectExist ? this.state.projectDoesntExistMessage : null;
        return (
            <MDBContainer fluid className="Project-detail-container">
                {
                    (this.state.showSuccessModal || this.state.showFailureModal) &&
                    <MessageModal
                        open={this.state.showSuccessModal || this.state.showFailureModal || false}
                        title={this.state.showSuccessModal ? this.state.saveTitle : this.state.failTitle}
                        message={message}
                        hideCancel={true}
                        primaryButtonText={"OK"}
                        onConfirm={() => this.closeMessageModal()} />
                }
                {this.state.showRecordCannotbeDeleted &&
                    <MessageModal
                        open={this.state.showRecordCannotbeDeleted}
                        title={"Delete Project"}
                        message={"Project Can not be Deleted"}
                        primaryButtonText={"Ok"}
                        secondaryButtonText={"Close"}
                        onConfirm={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                        handleClose={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                    />
                }
                <MessageModal
                    open={this.state.showDeleteConfirmationModal}
                    title={"Delete Project"}
                    message={"Are you sure you want to Delete?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.setState({ showDeleteConfirmationModal: false }, () => {
                        this.handleProjectDeleteConfirm()
                    })}
                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })} 
                />
                <Loader isOpen={this.state.isManagingQueue || this.state.isDeletingProject || this.state.isFetchingReport || false}
                    text={this.state.mode === 'queueAdd' ? "Adding..." : this.state.mode === "pdf" ? "Loading" : "Removing..."} />
                {
                    //permissions?.canViewProjects && 
                    (this.state.projectDetails?.is_view_project && (this.state.projectDetails?.is_confidential ? 
                        (permissions?.canViewConfidentialProject || permissions?.canAssignableToAProject) : 
                        permissions?.canViewProjects) &&
                    (this.state.doesThisProjectExist)) ? (
                        <>
                            <div className='weekend'>
                                <MDBRow>
                                    <MDBCol md={1} className={`${(this.state.file?.url || logo) ? "bg-dark picDiv" : "bg-dark altImg"}`}>
                                        <img
                                            src={this.state.file?.url || logo}
                                            alt='Logo' className="imageWidth"
                                        />
                                    </MDBCol>
                                    <MDBCol md={11}>
                                        <MDBRow>
                                            <div className='project'>
                                                <div>
                                                    <span className='project1'>Project</span> <span className='project2'>- {this.state.projectDetails?.project_title || ''}</span>
                                                    <span className='ml5'>
                                                        <MDBIcon
                                                            className="cursor-pointer"
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            onClick={this.handleProjectDetailsMenu}
                                                            icon={"ellipsis-h"}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="projectMenu">
                                                    <MenuField
                                                        // id={"type"}
                                                        id={`${this.state.projectDetails?.is_add_to_queue == 0 ? 'project-menu-items' : 'type'}`}
                                                        anchorEl={this.state.anchorEl}
                                                        open={this.state.anchorEl}
                                                        options={this.state.projectMenuOptions || []}
                                                        handleClose={() => this.setState({ anchorEl: null })}
                                                        onClick={this.onClickCallback}
                                                    />
                                                </div>
                                                <div>
                                                    {
                                                        this.state.projectDetails?.is_confidential ? <span className='Confidential'>Confidential</span> :
                                                            <span className='nonConfidential'>Non-Confidential</span>
                                                    }
                                                </div>

                                            </div>
                                            <div className='project'>
                                                <div>
                                                    <span className='nonConfidential'>{this.getCommaSeparatedValues('executive_name', this.state.projectDetails?.executives)}</span>
                                                </div>
                                                <div>
                                                    <span className='Confidential'>{this.state.projectDetails?.project_status_name || ''}</span>
                                                </div>

                                            </div>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className='tab-content'>
                                <div className='tool-tip' >
                                    <div>
                                        <TabsComponent
                                            tabList={this.state.projectTabList}
                                            value={this.state.tabValue}
                                            badgeContent={this.state.fileCount || 0}
                                            onChange={(e, v) => this.handleTabChange(v)}
                                        />
                                    </div>
                                    <div className='align-self'>
                                        <Tooltip placement="top" title={this.state.projectDetails?.material_location || ""}>
                                            <div><MDBIcon icon="map-marked-alt" size='sm' /></div>
                                        </Tooltip>

                                        <Tooltip placement="top" title={this.state.projectDetails?.vault_information || ""}>
                                            <div className='ml-2'><MDBIcon icon="lock" size='sm' /></div>
                                        </Tooltip>
                                    </div>
                                </div>


                                <div className="align-self">
                                    <BasicButton
                                        variant="outlined"
                                        type="inline"
                                        color="primary"
                                        // text={"Project Report"}
                                        text={this.state.isFetchingProjectReport ? <CircularProgress color="inherit" size={20} /> : "Project Report"}
                                        onClick={() => this.handleProjectReport()}
                                    />
                                    {
                                        this.handleText() &&
                                        <MDBCol md={4} className={`${this.state.tabValue === 2 ? 'add-exec-btn' : ''} edit-btn`}>
                                            <BasicButton
                                                variant="contained"
                                                type="inline"
                                                color="primary"
                                                text={this.handleText()}
                                                icon={this.handleIcon()}
                                                {...(this.state.tabValue === 6 && { uploadFile: (file) => this.handleNavigation(null, file, null) })}
                                                {...(this.state.tabValue === 6 && { inputType: 'file' })}
                                                onClick={() => this.handleNavigation()}
                                            />
                                        </MDBCol>
                                    }
                                </div>
                            </div>
                            {this.getTabComponent()}
                            {this.state.tabValue !=5 &&
                            <DraftsSection
                                projectId={this.state.projectId}
                                drafts={this.state.drafts || null}
                                isFetchingDrafts={this.state.isFetchingDrafts || false}
                                manageMessageModal={this.manageMessageModal}
                                divisionId={this.state.projectDetails?.division_id}
                            />
                            }
                            {this.state.tabValue==5 && this.state.showWGAformModal &&
                            <WGAContainer
                                open={this.state.showWGAformModal}
                                handleClose={() => this.setState({ showWGAformModal: false, wgaId: null })}
                                headerText={"WGA"}
                                mode={this.state.wgaId ? "Edit" : "New"}
                                primaryButtonText={this.props?.mode === "New" ? "Create" : "Save"}
                                secondaryButtonText={"Cancel"}
                                onClose={this.handleClose}
                                wgaNameChanged={this.wgaNameChanged}
                                modalName={"WGA"}
                                wgaId={this.state.wgaId || null}
                                projectId={this.state.projectId || null}
                                toggleRefreshPage={this.props.toggleRefreshPage}
                                setPostFlag={this.setPostFlag}
                                divisionId={this.state.divisionId}
                                fieldChanged={this.fieldChanged}
                                projectName={this.state.projectDetails.project_title}
                                wgaIdChanged={this.wgaIdChanged}
                            />
                            }
                        </> 
                    ) :
                    <MessageModal
                        open={this.state.showAccessDenied || !this.state.doesThisProjectExist || false}
                        title={this.state.showAccessDenied ? this.state.accessDeniedTitle : !this.state.doesThisProjectExist ? this.state.doesntExistTitle : ''}
                        message={message}
                        hideCancel={true}
                        primaryButtonText={"OK"}
                        onConfirm={() => this.closeMessageModal()} 
                    />
                }
            </MDBContainer>

        );
    }
})