import React from 'react';
import { MDBContainer } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import './MiscFiles.scss';
import * as Constants from '../../constants/constants'
import ScripterService from '../../services/service'
import { withUserContext } from '../../contexts/UserContext';
import FilelistItem from './FilelistItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import Loader from '../../Common/Loader/Loader';
import MessageModal from '../SharedComponents/MessageModal';
import {projectStatusesMakePrivate} from './config';
class MiscFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowCount: null,
            list: [],
            showLoader: false,
            showPageLoader: false,
            showError: false,
            showFail: false,
            showSuccess: false,
            saveTitle: "Success",
            saveEditMessage: "Uploaded successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            deleteSuccessMessage: "Record deleted successfully!",
            deleteFailureMesaage: "Deletion Failed!",
            isDelete: false,
            uploadLoaderText: null,
            isPrivateLoading:false
        }
    }

    componentDidMount() {
        this.getFiles();
        // add method in ref to call from parent componet

        this.props.fileRef.current = {
            onFileUpload: this.onFileUpload.bind(this),
            getFilelistCount: this.getFilelistCount.bind(this)
        }
    }
    onFileUpload=async(file)=> {
        console.log(file);
        let payload = {
            ...this.props?.filePayload,
            tenantName: this.props?.userContext?.active_tenant?.tenant_name,
            fileName: file?.name,
            isPrivate: projectStatusesMakePrivate.includes(this.props?.projectStatusId) ? 1 : 0
        }
       return await this.uploadFile(payload, file);
    }
    async getFilelistCount() {
        let count = await this.getFiles(true);
        return count;
    }
    getFiles() {
        // let canAddRemoveViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateProjectFiles === true ? true : false
        // let canAddRemoveViewPrivateSubmissionFiles=this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateSubmissionFiles === true?true:false
        let canAddPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateProjectFiles === true ? true : false
        let canViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateProjectFiles === true ? true : false
        let canRemovePrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateProjectFiles === true ? true : false
        let canAddPrivateSubmissionFiles=this.props?.userContext?.active_tenant?.permissions?.canAddPrivateSubmissionFiles === true ? true : false
        let canViewPrivateSubmissionFiles=this.props?.userContext?.active_tenant?.permissions?.canViewPrivateSubmissionFiles === true ? true : false
        let canRemovePrivateSubmissionFiles=this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateSubmissionFiles === true ? true : false
        let tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        let payload = { ...this.props?.filePayload }
        if (payload?.type === "submission") {
            // payload.isPrivate=canAddRemoveViewPrivateSubmissionFiles?1:0
            payload.isPrivate=(canAddPrivateSubmissionFiles || canViewPrivateSubmissionFiles || canRemovePrivateSubmissionFiles) ? 1 : 0
        } if (payload?.type === "project") {
            // payload.isPrivate=canAddRemoveViewPrivateProjectFiles?1:0
            payload.isPrivate=(canAddPrivateProjectFiles || canViewPrivateProjectFiles || canRemovePrivateProjectFiles) ? 1 : 0

        }
        payload.tenantName = tenantName;
        this.setState({ showLoader: true })
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                // console.log(response);
                this.setState({ showLoader: false })
                if (response.data?.error) {
                    console.log("Error from fetch file", response.data?.error)
                    return;
                }
                if (this.props?.toggleRefreshFileCount) {
                    let count = response.data?.Contents?.length;
                    this.props?.toggleRefreshFileCount(count);
                }
                let formattedList = response.data?.Contents?.map((file) => {
                    return {
                        "name": file.Key.replace(file.Prefix + "/", ""),
                        "path": file.Prefix,
                        "url": file.url,
                        isPrivate:file?.isPrivate
                    }
                })
                this.setState({ list: formattedList }, () => {
                    const rowCount = Math.ceil(this.state?.list?.length / 4);
                    if (rowCount && rowCount < 5) {
                        this.setState({ rowCount: 5 })
                    } else {
                        this.setState({ rowCount: rowCount })
                    }
                })
            }, err => {
                console.log('from misc files', err)
                this.setState({ showLoader: false })
            })
    }
    uploadFile(payload, file, checkValue) {
        if(this.props?.isConfidential){
            if (checkValue == 1 || checkValue == 0){
                payload['isPrivate'] =  checkValue;
            }else {
             this.props?.showPrivateCheck ?  payload['isPrivate'] =  1 : payload['isPrivate'] =  0;
            }
        }
        // if (this.props?.isConfidential) {
        //     payload['isPrivate'] =  1;
        // }
        this.setState({ showPageLoader: true, uploadLoaderText: this.state.isPrivateLoading?"Updating...":"Uploading..." });
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + `/uploadFile`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log(response);
                ScripterService.uploadFile(response.data.data, file).then(response => {
                    console.log("File Upload Successful:" + response.data);
                    this.setState({ showPageLoader: false,isPrivateLoading:false });
                    if (payload?.type === "submission") {
                        this.props?.handleSubmissionDelete(true);
                    } else {
                        this.props?.toggleRefreshPage(this.props?.filePayload?.type);
                    }
                    this.getFiles();
                }, err => {
                    console.log('Error from upload file', err)
                    this.setState({ showPageLoader: false });
                })
                this.getFiles();
            }, err => {
                console.log('Error from presigned url', err)
                this.setState({ showPageLoader: false });
            })
    }
    downloadFile(name) {
        let url = this.state?.list?.filter((obj) => obj.name === name)?.[0].url;
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }
    deleteFile(name) {
        // deleteFiles
        this.setState({ showPageLoader: true, isDelete: true, uploadLoaderText: null });
        let tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        let payload = { ...this.props?.filePayload, fileName: name, tenantName }
        ScripterService.deleteDataParams(Constants.scripterServiceBaseUrl + `/deleteFiles`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("error", response.data.error)
                    this.fieldChanged(true, "fail")
                    this.setState({ showPageLoader: false, isDelete: false,isPrivateLoading:false });
                    return;
                }
                this.setState({ showPageLoader: false, isDelete: false });
                this.getFiles();
                this.props?.toggleRefreshPage(this.props?.filePayload?.type)
            }, err => {
                this.fieldChanged(true, "fail")
                this.setState({ showPageLoader: false, isDelete: false });
                console.log('Error from presigned url', err)
            })
    }
    handleFilePrivate = (file, isPrivate) => {
        // if (isPrivate == 0 && !this.props?.filePermission) {
        //     return;
        // }
        let payload = { ...this.props?.filePayload }
        payload.tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        payload.fileName = file?.name;
        payload.isPrivate = isPrivate;
        this.setState({ isPrivateLoading:true }, () => {
                this.uploadFile(payload, file, isPrivate);
        })
    }
    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    closePopup() {
        this.setState({ showError: false, showFail: false, showSuccess: false })
    }
    render() {
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state?.isDelete ? this.state?.deleteFailureMesaage : this.state.failMessage :
            this.state.showSuccess ? this.state?.isDelete ? this.state?.deleteSuccessMessage : this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failTitle :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <MDBContainer fluid className="MiscFileContainer p-0">
                <Loader isOpen={this.state?.showPageLoader || false} text={`${this.state.uploadLoaderText ? this.state.uploadLoaderText : "Deleting..."}`}></Loader>
                <MessageModal
                    open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                    title={comfirmModalTitle}
                    message={message}
                    hideCancel={this.state.showFail || this.state.showSuccess || false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.closePopup()}
                    handleClose={(e) => this.closePopup()} />
                <MDBCard className="submissionMiscFilesCard">
                    <MDBCardBody className={`${this.state.rowCount > 5 && 'files-card-height'}`}>
                        <MDBCardText>
                            {this.state.showLoader ? (
                                <div className="ContentLoader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                    <>
                                        {this.state.list.length === 0 ?
                                            <span className="no-file-data">No file data found</span> :
                                            <div className="content">
                                                <div className="section-width">
                                                    {this.state?.list?.slice(0, this.state.rowCount)?.map((item, index) => (
                                                        <FilelistItem
                                                            file={item}
                                                            index={index}
                                                            fileObj={this.props?.filePayload}
                                                            downloadFile={this.downloadFile.bind(this)}
                                                            deleteFile={this.deleteFile.bind(this)}
                                                            canModifyFiles={this.props?.canModifyFiles}
                                                            handleFilePrivate={this.handleFilePrivate}
                                                            // filePermission={this.props?.filePermission}
                                                            showPrivateCheck={this.props?.showPrivateCheck}
                                                            viewPrivateFiles={this.props?.viewPrivateFiles}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="section-width">
                                                    {this.state?.list?.slice(this.state.rowCount, this.state.rowCount * 2)?.map((item, index) => (
                                                        <FilelistItem
                                                            file={item}
                                                            index={index+"1"}
                                                            fileObj={this.props?.filePayload}
                                                            downloadFile={this.downloadFile.bind(this)}
                                                            deleteFile={this.deleteFile.bind(this)}
                                                            canModifyFiles={this.props?.canModifyFiles}
                                                            handleFilePrivate={this.handleFilePrivate}
                                                            // filePermission={this.props?.filePermission}
                                                            showPrivateCheck={this.props?.showPrivateCheck}
                                                            viewPrivateFiles={this.props?.viewPrivateFiles}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="section-width">
                                                    {this.state?.list?.slice(this.state.rowCount * 2, this.state.rowCount * 3)?.map((item, index) => (
                                                        <FilelistItem
                                                            file={item}
                                                            index={index+"2"}
                                                            fileObj={this.props?.filePayload}
                                                            downloadFile={this.downloadFile.bind(this)}
                                                            deleteFile={this.deleteFile.bind(this)}
                                                            canModifyFiles={this.props?.canModifyFiles}
                                                            handleFilePrivate={this.handleFilePrivate}
                                                            // filePermission={this.props?.filePermission}
                                                            showPrivateCheck={this.props?.showPrivateCheck}
                                                            viewPrivateFiles={this.props?.viewPrivateFiles}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="section-width">
                                                    {this.state?.list?.slice(this.state.rowCount * 3)?.map((item, index) => (
                                                        <FilelistItem
                                                            file={item}
                                                            index={index+"3"}
                                                            fileObj={this.props?.filePayload}
                                                            downloadFile={this.downloadFile.bind(this)}
                                                            deleteFile={this.deleteFile.bind(this)}
                                                            canModifyFiles={this.props?.canModifyFiles}
                                                            handleFilePrivate={this.handleFilePrivate}
                                                            // filePermission={this.props?.filePermission}
                                                            showPrivateCheck={this.props?.showPrivateCheck}
                                                            viewPrivateFiles={this.props?.viewPrivateFiles}
                                                        />
                                                    ))}
                                                </div>
                                            </div>}
                                    </>
                                )}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard >
            </MDBContainer>
        )
    }
}

export default withUserContext(MiscFiles);