import React from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact"; import CancelIcon from '@material-ui/icons/Cancel';
import { 
    projectDetails, 
    akaList, 
    executiveList, 
    talentList, 
    sourceMaterialList, 
    filePayload, 
    talentPrefixes, 
    talentSuffixes 
} from './config';
import BasicTextField from '../../../../SharedComponents/BasicTextField/BasicTextField';
import DateField from '../../../../SharedComponents/DateField/DateField';
import BasicTextArea from '../../../../SharedComponents/BasicTextArea/BasicTextArea';
import SelectField from '../../../../SharedComponents/SelectField/SelectField';
import BasicCheckbox from '../../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicButton from '../../../../SharedComponents/BasicButton/BasicButton';
import SearchFieldWithAddValue from '../../../../SharedComponents/SearchFieldWithAddValue';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ScripterService from '../../../../../services/service';
import * as Constants from '../../../../../constants/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import BasicLabel from '../../../../SharedComponents/BasicLabel/BasicLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateCharNumOnlyWithSpace, validateCharNumWithHyphenSlash, getFormattedDate } from '../../../../../Common/Helper';
import messages from '../../../../../Common/Messages.json';
import sizeLimits from '../../../../../Common/SizeLimits.json';
import MessageModal from '../../../../SharedComponents/MessageModal';
import { withUserContext } from '../../../../../contexts/UserContext';
import SearchSelectField from '../../../../SharedComponents/SearchSelectField';
import SubAttachedFiles from '../../../Submissions/Dialogs/SubAttachedFiles';
import FileModal from '../../../../SharedComponents/FileModal';
import './ProjectContainer.scss';
import { withRouter } from 'react-router-dom';

export default withRouter(withUserContext(class ProjectContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            talentLoading: false,
            authorLoading: false,
            execLoading: false,
            projectDetails: [],
            selectedExecutiveValues: [],
            genreSelected: [],
            genreList: [],
            activeAkas: [],
            activeExecutive: [],
            activeTalent: [],
            activeMaterial: [],
            activeGenre: [],
            isSubmit: false,
            postInitiated: false,
            formEdited: false,
            akaIndex: 2,
            sourceIndex: 2,
            talentIndex: 2,
            executiveIndex: 2,
            isFetchingProjectTypes: false,
            isFetchingStatusTypes: false,
            isFetchingGenreTypes: false,
            isFetchingDistributionCompany: false,
            isFetchingSourceTypes: false,
            isFetchingProjectDetails: false,
            openFileModal: false,
            file: null,
            attachedModuleFiles: [],
            deletedFileList: [],
            isPrivateFileList: [],
            filePrefix: null,
            isDeleteFile:null,
            showDivisionChangeConfirmation: false, 
            ismpm: false
        }
        this.fileRef = React.createRef();
    }

    componentDidMount = () => {
        this.genreType();
        this.getProjectType();
        this.getStatusType();
        this.distributionCompany();
        this.getDivisions();
        this.sourceType();
        this.checkMode(this.props);
    }

    checkMode = (props) => {
        if (props.mode === "New") {
            this.setState({
                projectDetails: this.formatNewProjectDetailsResponse(
                    JSON.parse(JSON.stringify({ ...projectDetails }))
                )
            });

        } else if (props.mode === "Edit") {
            this.getFile(this.props?.projectId);
            this.getAttachedFiles(this.props?.projectId)
            this.getProjectDetails(this.props?.projectId);
        }
    }

    getProjectDetails = (id) => {
        this.setState({ isFetchingProjectDetails: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/project?projectId=${id}`,
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({
                    isFetchingProjectDetails: false,
                    projectDetails: this.formatNewProjectDetailsResponse(
                        response.data[0]
                    )
                });
            },
                (err) => {
                    this.setState({
                        isFetchingProjectDetails: false,
                        projectDetails: this.formatNewProjectDetailsResponse(
                            JSON.parse(JSON.stringify({ ...projectDetails })))
                    })
                })
    }

    getProjectType = () => {
        this.setState({ isFetchingProjectTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_project_type', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                this.setState({ featureType: formattedList, isFetchingProjectTypes: false });
            },
                (err) => {
                    this.setState({ isFetchingProjectTypes: false });
                    console.log("Error in fetching Feature Types:", err)
                })
    }

    getDivisions = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/entitySearch?entity=USER_DIVISIONS&searchString=`, 
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ divisions: formattedList }, () => {
                    this.setDefaultDivision();
                });
            },

                (err) => {
                    console.log("Error in fetching Division Details:", err)
                })
    }

    setDefaultDivision = () => {
        if (!this.state.projectDetails?.division_id) {
            let findWbPictureDivision = this.state.divisions?.find(item => item.label === 'WB Pictures');
            if (findWbPictureDivision) {
                this.setState(prevState => ({
                    projectDetails: {
                        ...prevState.projectDetails,
                        division_id: findWbPictureDivision?.value || null
                    },
                    postInitiated: false
                }))
            } else {
                this.setState(prevState => ({
                    projectDetails: {
                        ...prevState.projectDetails,
                        division_id: this.state.divisions?.[0]?.value || null
                    },
                    postInitiated: false
                }))
            }
        }
    }

    getStatusType = () => {
        this.setState({ isFetchingStatusTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_project_status', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                this.setState({ statusType: formattedList, isFetchingStatusTypes: false });
            },
                (err) => {
                    this.setState({ isFetchingStatusTypes: false });
                    console.log("Error in fetching Status Types:", err)
                })
    }

    genreType = () => {
        this.setState({ isFetchingGenreTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_genre', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                this.setState({ genreType: formattedList, genreList: formattedList, isFetchingGenreTypes: false });
            },
                (err) => {
                    this.setState({ isFetchingGenreTypes: false });
                    console.log("Error in fetching Genre Types:", err)
                })
    }

    sourceType = () => {
        this.setState({ isFetchingSourceTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_material_type', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                this.setState({ sourceType: formattedList, isFetchingSourceTypes: false });
            },
                (err) => {
                    this.setState({ isFetchingSourceTypes: false });
                    console.log("Error in fetching Genre Types:", err)
                })
    }

    distributionCompany = () => {
        this.setState({ isFetchingDistributionCompany: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_distribution_company', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item.is_active }));
                this.setState({ distributionCompany: formattedList, isFetchingDistributionCompany: false });
            },
                (err) => {
                    this.setState({ isFetchingDistributionCompany: false });
                    console.log("Error in fetching Distribution Company:", err)
                })
    }

    getTalentDetails = type => (event) => {
        if (event.target.value !== "") {
            type === 'talent' ? this.setState({ talentLoading: true }) : this.setState({ authorLoading: true });
            ScripterService.getData(Constants.scripterServiceBaseUrl +
                `/entitySearch?entity=TALENT&searchString=${event.target.value}`, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                   // let filteredList = response?.data?.filter(item => item?.roles?.includes('Writer'));
                    let formattedList = response?.data?.map(item => ({ value: item.talent_id, text: item.talent_name, writerTeam: item.writer_team }));
                    type === 'talent' ? this.setState({ talentLoading: false }) : this.setState({ authorLoading: false });
                    this.setState({ fieldOptions: formattedList });
                },
                    (err) => {
                        type === 'talent' ? this.setState({ talentLoading: false }) : this.setState({ authorLoading: false });
                        console.log("Error in fetching Talent Details:", err)
                    })
        } else {
            this.setState({ fieldOptions: [] });
            type === 'talent' ? this.setState({ talentLoading: false }) : this.setState({ authorLoading: false });
        }
    }

    getExecutiveDetails = (event) => {
        if (event.target.value !== "") {
            this.setState({ execLoading: true });
            let canAssignableToAProject = '"' + 'Assignable (and can view) a project' + '"';
            ScripterService.getData(Constants.scripterServiceBaseUrl +
                `/entitySearch?entity=USER&searchString=${event.target.value}&permissionName=${canAssignableToAProject}`, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedList = response?.data?.map(item => ({ value: item.user_id, text: item.user_name }));
                    this.setState({ executiveOptions: formattedList, execLoading: false });
                },
                    (err) => {
                        this.setState({ execLoading: false });
                        console.log("Error in fetching Talent Details:", err)
                    })
        } else {
            this.setState({ executiveOptions: [], execLoading: false });
        }
    }

    selectedTalent = index => (event, newValue) => {
        this.handleListEdit('talents', 'talent_name', newValue?.text || null, index)
        this.handleListEdit('talents', 'talent_id', !newValue?.writerTeam ? newValue?.value : null, index)
        this.handleListEdit('talents', 'talent_selected', newValue || null, index)
        this.handleListEdit('talents', 'talent_group_id', !newValue?.writerTeam ? null : newValue.value, index)
        this.setState({ fieldOptions: [] });
    }

    handleNewTalentDetails = (index, talentDetails) => {
        let newValue = {
            value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            text: (talentDetails?.talent_id ?
                // (talentDetails?.first_name + ' ' + (talentDetails?.last_name ? talentDetails?.last_name : '')) :
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list)),
        }
        this.handleListEdit('talents', 'talent_name', (talentDetails?.talent_id ?
            (talentDetails?.first_name + ' ' + (talentDetails?.last_name ? talentDetails?.last_name : '')) :
            this.getFormatTalentGroupName(talentDetails?.talent_group_members_list)) || null, index)
        this.handleListEdit('talents', 'talent_id', talentDetails?.talent_id, index)
        this.handleListEdit('talents', 'talent_selected', newValue || null, index)
        this.handleListEdit('talents', 'talent_group_id', talentDetails?.talent_group_id, index)
    }

    getFormatTalentGroupName = (talentTeamList) => {
        let talentGroupName = [];
        talentTeamList?.map(item => {
            talentGroupName.push(item?.talent_name)
        })
        return talentGroupName?.join(' & ');
    }

    getTalentName = (field, value) => {
        let prefixes = [ ...talentPrefixes ];
        let suffixes =[ ...talentSuffixes ];
        let n = value?.split(" ");
        if (field === "first_name" || field === "middle_name" || field === "last_name") {
            let prefixExist = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            if (prefixExist) {
                n.splice(0, 1);
            }
            let suffixExist = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length - 1]?.toLowerCase()));
            if (suffixExist) {
                n.pop();
            }
        }
        if (field === "first_name") {
            if (n?.length > 0) {
                return n[0];
            }
        } else if (field === "middle_name") {
            if (n?.length > 2) {
                return n[1];
            }
        } else if (field === "last_name") {
            if (value.split(" ").length > 1) {
                let lastName = [];
                n?.map((item, index) => {
                    if (n?.length <= 2 && index !== 0) {
                        lastName.push(item)
                    } else if (n?.length > 2 && index !== 0 && index !== 1) {
                        lastName.push(item)
                    }
                })
                return lastName.join(" ");
            }
        } else if (field === "prefix") {
            let prefix = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            return prefix;
        } else if (field === "suffix") {
            let suffix = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length -1]?.toLowerCase()));
            return suffix;
        }
    }

    handleTalentOnChange = index => (event, newValue) => {
        if(newValue) {
            if(!newValue?.hasOwnProperty('writerTeam')){
                let talentDetails = {}
                let prefixDetails = this.getTalentName("prefix", newValue?.value);
                if (prefixDetails) {
                    talentDetails['prefix_id'] = prefixDetails?.value;
                    talentDetails['prefix'] = prefixDetails?.label;
                }
                talentDetails['first_name'] = this.getTalentName("first_name", newValue?.value);
                talentDetails['middle_name'] = this.getTalentName("middle_name", newValue?.value) || null;
                talentDetails['last_name'] = this.getTalentName("last_name", newValue?.value) || null;
                let suffixDetails = this.getTalentName("suffix", newValue?.value);
                if (suffixDetails) {
                    talentDetails['suffix_id'] = suffixDetails?.value;
                    talentDetails['suffix'] = suffixDetails?.label;
                }
                this.props?.navFuns?.handleShowTalentPopup(null, 0, "new",this.handleNewTalentDetails.bind(this,index), talentDetails)
            } else  {
                this.handleListEdit('talents', 'talent_name', newValue?.text || null, index)
                this.handleListEdit('talents', 'talent_id', !newValue?.writerTeam ? newValue?.value : null, index)
                this.handleListEdit('talents', 'talent_selected', newValue || null, index)
                this.handleListEdit('talents', 'talent_group_id', !newValue?.writerTeam ? null : newValue.value, index)
                this.setState({ fieldOptions: [] });
            }
        }
    }

    // selectedAuthor = index => (event, newValue) => {
    //     this.handleListEdit('source_material', 'author_id', !newValue?.writerTeam ? newValue.value : null, index)
    //     this.handleListEdit('source_material', 'talent_group_id', newValue?.writerTeam ? newValue.value : null, index)
    //     this.handleListEdit('source_material', 'author', newValue.text, index)
    //     this.handleListEdit('source_material', 'author_selected', newValue, index)
    //     this.setState({ fieldOptions: [] });
    // }

    selectedExecutive = index => (event, newValue) => {
        if (newValue) {
            this.handleListEdit('executives', 'executive_id', newValue.value, index)
            this.handleListEdit('executives', 'executive_name', newValue.text, index)
            this.handleListEdit('executives', 'executive_selected', newValue, index)
            this.setState({ executiveOptions: [] });
        }
        else {
            this.handleListEdit('executives', 'executive_id', null, index)
            this.handleListEdit('executives', 'executive_name', null, index)
            this.handleListEdit('executives', 'executive_selected', null, index)
            this.setState({ executiveOptions: [] });
        }

    }



    formatNewProjectDetailsResponse = (response) => {
        response['project_type_id_selected'] = {
            'value': response.project_type_id, 'text': response.project_type_name
        }
        response['project_status_id_selected'] = {
            'value': response.project_status_id, 'text': response.project_status_name
        }
        response['distribution_company_id_selected'] = {
            'value': response.distribution_company_id, 'text': response.distribution_company_name
        }
        if (response?.akas?.length === 0) {
            response?.akas.push(JSON.parse(JSON.stringify({ ...akaList })));
        }
        if (response?.source_material?.length === 0) {
            response?.source_material.push(JSON.parse(JSON.stringify({ ...sourceMaterialList })));
        } else {
            response?.source_material?.map((source_material) => {
                let r = { 'value': source_material.author_id, 'text': source_material.author };
                let s = { 'value': source_material.type_id, 'text': source_material.type_name };
                source_material['author_selected'] = r;
                source_material['type_id_selected'] = s;
            });
        }
        if (response?.executives?.length === 0) {
            response?.executives.push(JSON.parse(JSON.stringify({ ...executiveList })));
        } else {
            response?.executives?.map((executives) => {
                let r = { 'value': executives.executive_id, 'text': executives.executive_name }
                executives['executive_selected'] = r;
            });
        }
        if (response?.talents?.length === 0) {
            response?.talents.push(JSON.parse(JSON.stringify({ ...talentList })));
        } else {
            response?.talents?.map((talent) => {
                let r = { 'value': talent?.talent_id || talent?.talent_group_id, 'text': talent.talent_name }
                talent['talent_selected'] = r;
            });
        }
        let temp = [];
        response?.genres?.map((talent) => {
            let r = {
                'value': talent.genre_id, 'is_delete': talent.is_delete, 'project_genre_id': talent.project_genre_id,
                // 'text': !talent.genre_id ? '' : this.state.genreType?.find(item => talent.genre_id === item.value).text
                'text' : talent?.genre_name
            }
            temp.push(r);
        });
        this.setState({ genreSelected: temp }, () => {
            this.handleGenre();
        })
        return response;
    }

    handleGenre = () => {
        if (this.state.genreSelected?.length !== 0) {
            let genreListType = this.state?.genreList?.filter(obj => this.state?.genreSelected?.find(item => item.text === obj.text) ? false : true);
            this.setState({ genreType: genreListType })
        } else {
            this.setState({ genreType: [...this.state.genreList] })
        }
    }

    handleDivisionChange = (field, value) => {
        if (this.props?.mode === 'Edit' && this.state.projectDetails?.division_id) {
            this.setState({ 
                showDivisionChangeConfirmation: true,
                projectDivisionId: value 
            });
        } else {
            this.handleGeneralEdit(field, value);
        }
    }

    handleGeneralEdit = (field, value) => {
        if (field === 'is_confidential' || field === 'production_start_date_tbd' || field === 'wrap_date_tbd' ||
            field === 'release_date_tbd' || field === "rights_lapse_date_tbd") {
            this.setState(prevState => ({
                projectDetails: {
                    ...prevState.projectDetails,
                    [field]: value ? 1 : 0
                }
            }))
        } else if (field === 'project_type_id' || field === 'project_status_id' || field === 'project_genre_id' || field === 'distribution_company_id') {
            let projectDetails = this.state.projectDetails;
            projectDetails[field] = value.value;
            projectDetails[field + '_selected'] = {
                'value': value.value, 'text': value.text
            };
            this.setState({ projectDetails: projectDetails })
        } else if (field === 'genres') {
            let temp = [];
            this.state.activeGenre?.map(item => {
                temp.push(item);
            })
            this.state.genreSelected?.map(item => {
                if (item.project_genre_id) {
                    let count = 0;
                    let a = value.map(value => {
                        if (item.project_genre_id === value.project_genre_id) {
                            count++;
                        }
                    })
                    if (count === 0) {
                        item.is_delete = 1;
                        temp.push({
                            'genre_id': item.value, 'genre_name': item.text, 'project_genre_id': item.project_genre_id,
                            'is_delete': 1
                        })
                    }
                }
            })
            this.setState({ activeGenre: temp })
            let newRole = value.map(item => {
                return {
                    'value': item.value, 'text': item.text, 'project_genre_id': !item.project_genre_id ? null : item.project_genre_id,
                    'is_delete': !item.is_delete ? 0 : item.is_delete
                }
            })
            let val = value.map(item => {
                return {
                    'genre_id': item.value, 'genre_name': item.text, 'project_genre_id': !item.project_genre_id ? null : item.project_genre_id,
                    'is_delete': 0
                }
            })
            this.setState({ genreSelected: newRole }, () => {
                this.handleGenre();
            });
            this.setState(prevState => ({
                projectDetails: {
                    ...prevState.projectDetails,
                    [field]: val || null
                }
            }))
        }
        else {
            this.setState(prevState => ({
                projectDetails: {
                    ...prevState.projectDetails,
                    [field]: value || null
                }
            }))
        }
    }

    handleListEdit = (check, field, value, index) => {
        let projectDetails = this.state.projectDetails;
        switch (check) {
            case 'akas':
                let akalist = projectDetails[check][index];
                let aka_item = {
                    ...akalist,
                    [field]: value
                };
                projectDetails[check][index] = aka_item;
                break;

            case 'source_material':
                let sourceList = projectDetails[check][index];
                let source_item = {
                    ...sourceList,
                    [field]: field === 'type_id' ? value.value : value
                };
                projectDetails[check][index] = source_item;
                if (field === 'type_id') {
                    this.handleListEdit('source_material', 'type_id_selected', value, index)
                }
                break;

            case 'talents':
                let talentList = projectDetails[check][index];
                let talent_item = {
                    ...talentList,
                    [field]: value
                };
                projectDetails[check][index] = talent_item;
                break;

            case 'executives':
                let executiveList = projectDetails[check][index];
                let executive_item = {
                    ...executiveList,
                    [field]: value
                };
                projectDetails[check][index] = executive_item;
                break;

        }

        this.setState({ projectDetails: projectDetails, formEdited: true });
        this.props.fieldChanged(this.state.formEdited, "change");
    }

    addAdditionalField = (listName, index) => {
        this.setState({ formEdited: true })
        this.props.fieldChanged(this.state.formEdited, "change");
        let projectDetails = this.state.projectDetails;
        switch (listName) {
            case 'akas':
                if (projectDetails.akas[index].aka) {
                    let akaArray = JSON.parse(JSON.stringify({ ...akaList }));
                    let aka_list = [...projectDetails.akas];
                    akaArray.index = this.state.akaIndex;
                    aka_list.push(akaArray);
                    projectDetails.akas = aka_list;
                    this.setState({ akaIndex: this.state.akaIndex + 1, projectDetails: projectDetails, postInitiated: false });
                }
                break;
            case 'source_material':
                if (projectDetails.source_material[index].type_id && projectDetails.source_material[index].title &&
                    projectDetails.source_material[index].author && projectDetails.source_material[index].publisher) {
                    let sourceArray = JSON.parse(JSON.stringify({ ...sourceMaterialList }));
                    let source_list = [...projectDetails.source_material];
                    sourceArray.index = this.state.sourceIndex;
                    source_list.push(sourceArray);
                    projectDetails.source_material = source_list;
                    this.setState({ sourceIndex: this.state.sourceIndex + 1, projectDetails: projectDetails, postInitiated: false });
                }
                break;
            case 'talents':
                if (projectDetails.talents[index].talent_name) {
                    let talentArray = JSON.parse(JSON.stringify({ ...talentList }));
                    let talent_list = [...projectDetails.talents];
                    talentArray.index = this.state.talentIndex;
                    talent_list.push(talentArray);
                    projectDetails.talents = talent_list;
                    this.setState({ talentIndex: this.state.talentIndex + 1, projectDetails: projectDetails, postInitiated: false });
                }
                break;
            case 'executives':
                if (projectDetails.executives[index].executive_name) {
                    let executivesArray = JSON.parse(JSON.stringify({ ...executiveList }));
                    let executives_list = [...projectDetails.executives];
                    executivesArray.index = this.state.executiveIndex;
                    executives_list.push(executivesArray);
                    projectDetails.executives = executives_list;
                    this.setState({ executiveIndex: this.state.executiveIndex + 1, projectDetails: projectDetails, postInitiated: false });
                }
                break;
        }
    }
    removeField = (listName, callbackItem, index) => {
        this.setState({
            formEdited: true,
            listName: listName, callbackItem: callbackItem, postInitiated: false, index: index
        }, () => {
            switch (listName) {
                case 'akas':
                    if (callbackItem.project_aka_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'source_material':
                    if (callbackItem.project_source_material_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'talents':
                    if (callbackItem.project_talent_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'executives':
                    if (callbackItem.project_executive_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;

            }
        })
        //this.props.fieldChanged(this.state.formEdited, "change");

    }

    showMessage = () => {
        this.setState({ isOpen: true });
    }

    deleteRow = (listName, callbackItem, type) => {
        let projectDetails = this.state.projectDetails;
        switch (listName) {
            case 'akas':
                projectDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.activeAkas?.map((item) => {
                        temp.push(item);
                    });
                    let aka_list = projectDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(aka_list[0]);
                    this.setState({ activeAkas: temp })
                }
                let aka_list = projectDetails[listName].filter(item =>
                    (item.project_aka_id !== callbackItem.project_aka_id) || (item.index !== callbackItem.index)
                );
                if (aka_list.length === 0) {
                    projectDetails[listName] = [{ ...akaList }];
                } else {
                    projectDetails[listName] = aka_list;
                }


                break;
            case 'source_material':
                projectDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.activeMaterial?.map((item) => {
                        temp.push(item);
                    });
                    let source_list = projectDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(source_list[0]);
                    this.setState({ activeMaterial: temp })
                }
                let source_list = projectDetails[listName].filter(item =>
                    (item.project_source_material_id !== callbackItem.project_source_material_id) || (item.index !== callbackItem.index)
                );
                if (source_list.length === 0) {
                    projectDetails[listName] = [{ ...sourceMaterialList }];
                } else {
                    projectDetails[listName] = source_list;
                }

                break;
            case 'talents':
                projectDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.activeTalent?.map((item) => {
                        temp.push(item);
                    });
                    let talent_list = projectDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(talent_list[0]);
                    this.setState({ activeTalent: temp })
                }
                let talent_list = projectDetails[listName].filter(item =>
                    (item.project_talent_id !== callbackItem.project_talent_id) || (item.index !== callbackItem.index)
                );
                if (talent_list.length === 0) {
                    projectDetails[listName] = [{ ...talentList }];
                } else {
                    projectDetails[listName] = talent_list;
                }


                break;
            case 'executives':
                projectDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.activeExecutive?.map((item) => {
                        temp.push(item);
                    });
                    let executive_list = projectDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(executive_list[0]);
                    this.setState({ activeExecutive: temp })
                }

                let executive_list = projectDetails[listName].filter(item =>
                    (item.project_executive_id !== callbackItem.project_executive_id) || (item.index !== callbackItem.index)
                );
                if (executive_list.length === 0) {
                    projectDetails[listName] = [{ ...executiveList }];
                } else {
                    projectDetails[listName] = executive_list;
                }


                break;
        }
        this.setState({
            projectDetails: projectDetails, listName: null, callbackItem: null, postInitiated: false, isOpen: false
        });
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true }, () => {
            let projectDetailsJson = this.setProjectDetailsUniqueEntries();
            if (this.validateJson(projectDetailsJson)) {
                this.getFilteredJson(projectDetailsJson);
                projectDetailsJson.talents = projectDetailsJson?.talents?.filter(item => item.talent_id || item.talent_group_id);
                projectDetailsJson.production_start_date = getFormattedDate(projectDetailsJson.production_start_date, true)
                projectDetailsJson.release_date = getFormattedDate(projectDetailsJson.release_date, true)
                projectDetailsJson.wrap_date = getFormattedDate(projectDetailsJson.wrap_date, true)
                projectDetailsJson.rights_lapse_date = getFormattedDate(projectDetailsJson.rights_lapse_date, true)
                projectDetailsJson.source_material.map((item) => item.date = getFormattedDate(item.date, true))
                projectDetailsJson.source_material.map((item) => item.copyright_date = getFormattedDate(item.copyright_date, true))
                this.postProjectDetails(projectDetailsJson);
            }
        })
    }

    getFilteredJson = (projectDetailsJson) => {
        let akaTemp = [];
        let talentTemp = [];
        let executiveTemp = [];
        let materialTemp = [];
        let genreTemp = [];
        this.state.activeGenre?.map(item => {
            genreTemp?.push(item);
        });
        projectDetailsJson.genres?.map(item => {
            genreTemp?.push(item);
        });
        projectDetailsJson.genres = genreTemp;
        this.state.activeAkas?.map(item => {
            akaTemp?.push(item);
        });
        projectDetailsJson.akas?.map(item => {
            akaTemp?.push(item);
        });
        projectDetailsJson.akas = akaTemp;
        this.state.activeExecutive?.map(item => {
            executiveTemp?.push(item);
        });
        projectDetailsJson.executives?.map(item => {
            executiveTemp?.push(item);
        });
        projectDetailsJson.executives = executiveTemp;
        this.state.activeMaterial?.map(item => {
            materialTemp?.push(item);
        });
        projectDetailsJson.source_material?.map(item => {
            materialTemp?.push(item);
        });
        projectDetailsJson.source_material = materialTemp;
        this.state.activeTalent?.map(item => {
            talentTemp?.push(item);
        });
        projectDetailsJson.talents?.map(item => {
            talentTemp?.push(item);
        });
        projectDetailsJson.talents = talentTemp;
        let projectFiles = this.state.attachedModuleFiles?.find((item) => item?.isPrivate === 1)
        if (this.props?.mode === "Edit" && projectFiles) {
            projectDetailsJson.files = { Contents: this.state.attachedModuleFiles, Prefix: this.state.filePrefix }
        }
        return projectDetailsJson;
    }

    postProjectDetails = (projectDetailsJson) => {
        this.setState({ isSubmit: true })
        this.props.setPostFlag(true);
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/project', projectDetailsJson,
            this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ postInitiated: false, formEdited: false, isSubmit: false });
                    this.props.setPostFlag(false);
                    this.props.fieldChanged(true, "fail");
                } else if(response.data[0].message === "MPMNo Already Exists"){
                    this.setState({ismpm: true, isSubmit: false});
                } else {
                    this.setState({ postInitiated: false, formEdited: false, isSubmit: false });
                    this.handleFileActions(this.props?.mode === "Edit"?this.props?.projectId:response.data[0]?.project_id)
                    if (this.state.file?.lastModified) this.uploadFile(this.props?.mode === "Edit"?this.props?.projectId:response.data[0]?.project_id);
                    this.props.setPostFlag(false);
                    this.props.toggleRefreshPage('project');
                    this.props.fieldChanged(true, "success");
                    if (window.location.pathname === "/") {
                        this.props.history.push("/")
                    }
                    if (this.props?.mode === "New") {
                        let url = '/projectDetails/' + response?.data[0]?.project_id
                        this.props.history.push(url)
                        // window.open(url);
                    }
                }
            },
                (err) => {
                    this.props.fieldChanged(true, "fail");
                    this.props.setPostFlag(false);
                    console.log("Post project details error: " + err);
                });
    }
    checkFieldLength(value,size){
        return value ? value?.length <=size : true
    }
    validateJson = (projectDetailsJson) => {
        let canSubmit = 
            projectDetailsJson.project_title 
            && this.checkFieldLength(projectDetailsJson.project_title,sizeLimits.largeNameCharLimit)
            && projectDetailsJson?.project_status_id  
            && this.checkFieldLength(projectDetailsJson?.sap_dev, sizeLimits.nameCharacterLimit)
            && this.checkFieldLength(projectDetailsJson?.sap_prod,sizeLimits.nameCharacterLimit)
            && this.checkFieldLength(projectDetailsJson?.log_line,sizeLimits.largeNotes)                
            && (projectDetailsJson.akas?.length > 0 ? projectDetailsJson.akas.every(aka => this.checkFieldLength(aka.aka,sizeLimits.largeNameCharLimit)):true)
            && (!projectDetailsJson?.material_location ? true : this.checkFieldLength(projectDetailsJson?.material_location,sizeLimits.nameCharacterLimit))
            // && (!projectDetailsJson.vault_information ? true :
            //         this.checkFieldLength(projectDetailsJson.vault_information,sizeLimits.nameCharacterLimit))
            && (!projectDetailsJson.project_notes ? true :
                    this.checkFieldLength(projectDetailsJson.project_notes,sizeLimits.largeNotes))
            && (projectDetailsJson.source_material?.length > 0 ?
                    projectDetailsJson.source_material?.every(source_material =>                        
                        this.checkFieldLength(source_material.title,sizeLimits.largeNameCharLimit)
                                && this.checkFieldLength(source_material?.publisher,sizeLimits.largeNameCharLimit)
                                && this.checkFieldLength(source_material?.author,sizeLimits.largeNameCharLimit)):true)
            && (projectDetailsJson.executives?.length > 0 ? projectDetailsJson.executives?.every(executives =>
                    !(executives.executive_id) ? true : executives.executive_id) : true)
            && (projectDetailsJson.talents?.length > 0 ? projectDetailsJson?.talents?.every(talent =>
                    !(talent.talent_id) ? true : talent.talent_id) : true)
           
        return canSubmit
    }

    setProjectDetailsUniqueEntries = () => {
        let projectDetails = { ...this.state.projectDetails };
        projectDetails.akas = [...projectDetails.akas].filter((tag, index, array) =>
            (array.findIndex(t => t.aka == tag.aka) == index) && tag.aka);
        // projectDetails.source_material =  [...projectDetails.source_material].filter((tag, index, array) => (tag.type_id || tag.title || tag.author_id || tag.publisher))
        // projectDetails.source_material = [...projectDetails.source_material].filter((tag, index, array) =>
        // (array.findIndex(t => t.type_id == tag.type_id && t.title == tag.title && t.author_id == tag.author_id && t.publisher == tag.publisher) == index) &&
        // tag.type_id && tag.title && tag.author_id && tag.publisher); 
        projectDetails.executives = [...projectDetails.executives].filter((tag, index, array) =>
            (array.findIndex(t => t.executive_id == tag.executive_id) == index)
            && (tag.executive_name));
        // projectDetails.talents = [...projectDetails.talents].filter((tag, index, array) =>
        //     (array.findIndex(t => t.talent_id == tag.talent_id) == index)
        //     && (tag.talent_id));
        return projectDetails;
    }

    handleFileUpload = (file) => {
        this.setState({ file: file });
    }

    uploadFile = (project_id) => {
        let payload = JSON.parse(JSON.stringify({ ...filePayload }));
        payload.tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        payload.fileName = this.state.file?.name;
        payload.projectId = project_id;
        payload.type= "project",
        payload.module= 'project',
        payload.subModule= 'poster',
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + `/uploadFile`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log(response);
                ScripterService.uploadFile(response.data.data, this.state.file).then(response => {
                    console.log("File Upload Successful:" + response.data);
                    this.props.toggleRefreshPage('project');
                }, err => {
                    console.log('Error from upload file', err)
                })
            }, err => {
                console.log('Error from presigned url', err)
            })
    }
    getAttachedFiles(project_id) {
        // let canAddRemoveViewPrivateProjectFiles=this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateProjectFiles === true?true:false
        let canAddPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateProjectFiles === true ? true : false
        let canViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateProjectFiles === true ? true : false
        let canRemovePrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateProjectFiles === true ? true : false
        let payload = JSON.parse(JSON.stringify({ ...filePayload }));
        payload.tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        payload.fileName = this.state.file?.name;
        payload.projectId = project_id;
        // payload.isPrivate = canAddRemoveViewPrivateProjectFiles ? 1 : 0;
        payload.isPrivate = (canAddPrivateProjectFiles || canViewPrivateProjectFiles || canRemovePrivateProjectFiles) ? 1 : 0;
        payload.type= "project",
        payload.module= 'project',
        payload.subModule= 'misc',
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("getFile", response);
                // if (response.data?.error) {
                //     console.log("Error from fetch file", response.data?.error)
                //     return;
                // }
                let formattedList = response.data?.Contents?.map((file) => {
                    return {
                        "name": file.Key.replace(file.Prefix + "/", ""),
                        "Key":file?.Key,
                        isPrivate: file?.isPrivate,
                        //"path": file.Prefix,
                        "url": file.url,
                        type: "project",
                        "isDownloadable": true
                    }
                })
                this.setState({ attachedModuleFiles:formattedList ,filePrefix: response.data?.Prefix})
            }, err => {
                console.log('from misc files', err)
            })
    }

    getFile(project_id){
        let payload = JSON.parse(JSON.stringify({ ...filePayload }));
        payload.tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        payload.fileName = this.state.file?.name;
        payload.projectId = project_id;
        payload.type= "project",
        payload.module= 'project',
        payload.subModule= 'poster',
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("getFile", response);
                // if (response.data?.error) {
                //     console.log("Error from fetch file", response.data?.error)
                //     return;
                // }
                let formattedList = response.data?.Contents?.map((file) => {
                    return {
                        "name": file.Key.replace(file.Prefix + "/", ""),
                        "Key":file?.Key,
                        //"path": file.Prefix,
                        "url": file.url
                    }
                })
                this.setState({ file: formattedList[0]})
            }, err => {
                console.log('from misc files', err)
            })
    }

    handleProjectFileUpload = (file, attachedFiles) => {
        this.setState({
            attachedFile: file,
            attachedModuleFiles: [...this.state.attachedModuleFiles, ...attachedFiles]
        })
    }

    handleFileDelete = (file, isDeleteFile) => {
        let fileList = this.state.attachedModuleFiles;
        const fileIndex = fileList?.indexOf(file);
        if (fileIndex > -1) {
            fileList?.splice(fileIndex, 1);
        }
        this.setState({
            isDeleteFile: isDeleteFile,
            attachedModuleFiles: fileList,
            deletedFileList: [...this.state.deletedFileList, file]
        })
    }
    handleFilePrivate = (file, isPrivate,index) => {
        // if ((isPrivate == 0 && isPrivate == 1) && (this.props?.userContext?.user_profile?.group_name != 'Feature Story Admin' ||
        //     this.props?.userContext?.user_profile?.user_id != this.state.projectDetails?.created_by)
        // ) {
        //     return;
        // }
        let fileList = [...this.state.attachedModuleFiles];
        const fileIndex = fileList?.indexOf(file);
        if (fileIndex > -1) {
        fileList?.map((item,fIndex) => {
            if (item.name === file?.name && fIndex === index) {
               return item.isPrivate = isPrivate
            }
        })
        }
        this.setState({
            isPrivateFile: isPrivate,
            attachedModuleFiles: fileList,
            isPrivateFileList: [...this.state.isPrivateFileList, file]
        })
    }
    handleFileActions = async (projectId) => {
        let promises = [];
        if (this.state.attachedModuleFiles.length > 0 ) {
            promises = await this.state.attachedModuleFiles?.map((file) => (this.fileRef.current.onFileUpload(file, projectId)))
        }
        if (this.props?.mode === "Edit" && this.state.isDeleteFile && this.state.deletedFileList.length > 0) {
            let a = await this.state.deletedFileList?.map((file) => (file?.url ? this.fileRef.current.deleteFile(file.name) : null
            ))

            promises = [...promises, ...a]
        }
        await Promise.allSettled(promises).then(async (results) => await setTimeout(() => {
            this.props.toggleRefreshPage('project');
        }, 0));
    }


    render() {
        let isLoading = this.state.isFetchingProjectTypes || this.state.isFetchingStatusTypes || this.state.isFetchingGenreTypes
            || this.state.isFetchingDistributionCompany || this.state.isFetchingSourceTypes || this.state.isFetchingProjectDetails;
        return (
            <div >
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (<MDBContainer fluid className="ModalContainer ProjectContainer">
                    <MessageModal
                        open={this.state.ismpm}
                        title={"Information"}
                        message={"MPMNo Already Exists for another project"}
                        primaryButtonText={"OK"}
                        hideCancel={true}
                        onConfirm={(e) => this.setState({ ismpm: false })}
                    />
                    <MessageModal
                        open={this.state.isOpen}
                        title={"Delete record"}
                        message={"Would you like to delete saved record?"}
                        primaryButtonText={"Delete"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => this.deleteRow(this.state.listName, this.state.callbackItem, 'edit')}
                        handleClose={(e) => this.setState({ isOpen: false })} 
                    />
                    <MessageModal
                        open={this.state.showDivisionChangeConfirmation}
                        title={"Change Division"}
                        message={"Would you like to change the division?"}
                        primaryButtonText={"Ok"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => {
                            this.handleGeneralEdit('division_id', this.state.projectDivisionId)
                            this.handleGeneralEdit('is_division_change', 1);
                            this.setState({ showDivisionChangeConfirmation: false })
                        }}
                        handleClose={(e) => this.setState({ showDivisionChangeConfirmation: false })} 
                    />
                    {
                        this.state.openFileModal &&
                            <FileModal open={this.state.openFileModal || false}
                            id={"poster-upload"}
                            handleFileUpload={this.handleFileUpload}
                            file={this.state.file}
                            handleClose={() => this.setState({ openFileModal: false })} />
                    }
                    <MDBRow>
                        <MDBCol md={4} className="studioDivision p-0">
                            <SelectField
                                id={"studioDivision"}
                                label={"Studio Division"}
                                defaultMenuText={"-Select-"}
                                isMandatory={false}
                                options={this.state.divisions || []}
                                value={this.state.projectDetails?.division_id || ""}
                                onChange={(e) =>this.handleDivisionChange("division_id", e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="ModalContent" id='ProjectContainer'>
                        <MDBCol>
                            <MDBRow className="ModalBody p-2">
                                <MDBCol md={12} className="Content">

                                    <span className="project-subheading">Details</span>
                                    <MDBRow>
                                        <MDBCol md={4}>
                                            <BasicTextField
                                                label="Project Title"
                                                isMandatory={true}
                                                limit={sizeLimits.largeNameCharLimit}
                                                showMandatory={this.state.postInitiated}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                limitWarning={messages.exceed300CharacterWarning || ""}
                                                value={this.state.projectDetails?.project_title || ""}
                                                onChange={(e) => {
                                                    this.handleGeneralEdit('project_title', e.target.value)
                                                }} />
                                        </MDBCol>
                                        <MDBCol md={4} id="Imagebtn">
                                            <BasicLabel text={"Poster Image"} />
                                            <div className="file-div-project">
                                                <BasicButton
                                                    className="poster-button"
                                                    variant="outlined"
                                                    text={"Choose File"}
                                                    disabled={false}
                                                    onClick={() => this.setState({ openFileModal: true })}
                                                    accept={["image/*"]}
                                                />
                                                <span className="file-name">{this.state.file?.name || ''}</span>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <BasicTextField
                                                label="SAP(Dev)"                                               
                                                limit={sizeLimits.nameCharacterLimit}                                               
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                                onChange={(e) => {
                                                    this.handleGeneralEdit('sap_dev', e.target.value)
                                                }}
                                                value={this.state.projectDetails?.sap_dev || ""} />
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <BasicTextField
                                                label="SAP(Prod)"                                               
                                                limit={sizeLimits.nameCharacterLimit}                                               
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                                onChange={(e) => {
                                                    this.handleGeneralEdit('sap_prod', e.target.value)
                                                }}
                                                value={this.state.projectDetails?.sap_prod || ""} />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md={2} id="checkBox">
                                            <BasicCheckbox
                                                id={'is_confidential'}
                                                label="Confidential"
                                                checked={this.state.projectDetails?.is_confidential === 1 ? true : false}
                                                onChange={(e) =>
                                                    this.handleGeneralEdit('is_confidential', e.target.checked)
                                                }
                                                align="start"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md={6}>
                                            {
                                                this.state.projectDetails?.akas?.map((aka, index) => (
                                                    (aka.is_delete === 0 ?
                                                        <MDBRow>
                                                            <MDBCol md={10} id="Akas">
                                                                <BasicTextField
                                                                    label={index === 0 ? "AKA(s)" : null}
                                                                    value={aka.aka || ""}
                                                                    // showAsLabel={aka.project_aka_id ? true : false}
                                                                    limit={sizeLimits.largeNameCharLimit}
                                                                    limitWarning={messages.exceed300CharacterWarning || ""}
                                                                    onChange={(e) =>
                                                                        this.handleListEdit('akas', 'aka', e.target.value.length > 0 ? e.target.value : null, index)
                                                                    } />
                                                            </MDBCol>
                                                            <MDBCol md={2} className="actionButonsSize">
                                                                <MDBRow className={index === 0 ? "actionButons" : ""}>
                                                                    {
                                                                            <MDBCol md={1} className="actions alignField">
                                                                                <MDBIcon icon="trash-alt" size='sm' onClick={(e) =>
                                                                                    this.removeField('akas', aka, index)
                                                                                } />

                                                                            </MDBCol>
                                                                    }
                                                                    <MDBCol md={1} className="actions alignField">
                                                                        {
                                                                            (this.state.projectDetails?.akas.length - 1 === index && aka.aka?.length > 0) ?
                                                                                <AddCircleIcon fontSize="small"
                                                                                    onClick={(e) =>
                                                                                        //console.log('add')
                                                                                        this.addAdditionalField('akas', index)
                                                                                    } /> : null
                                                                        }
                                                                    </MDBCol>
                                                                </MDBRow>
                                                            </MDBCol>
                                                        </MDBRow> : null)
                                                ))}
                                        </MDBCol>
                                        <MDBCol md={6}>
                                            <MDBRow md={12}>
                                                <BasicTextArea
                                                    value={this.state.projectDetails?.log_line || null}
                                                    label={"Logline"}
                                                    className={"textArea"}
                                                    rows={3}                                                    
                                                    limit={sizeLimits.largeNotes}
                                                    limitWarning={messages.exceed1000CharacterWarning || ""}
                                                    onChange={(e) =>
                                                        this.handleGeneralEdit('log_line', e.target.value.length > 0 ? e.target.value : null)
                                                    }
                                                    typedCharCount={this.state.projectDetails?.log_line?.length || 0}
                                                    showCharCount={true}
                                                />
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow id='rolesDropDown'>
                                        <MDBCol md={4} className="col-content">
                                            <SearchSelectField id={"projectType"}
                                                label={"Project Type"}                                                
                                                options={this.state.featureType || []}
                                                multiple={false} width={'100%'}
                                                value={this.state.projectDetails?.project_type_id_selected || []}
                                                onChange={(e, newValue) =>
                                                    //console.log(e)
                                                    this.handleGeneralEdit('project_type_id', newValue)
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md={4} className="col-content">
                                            <SearchSelectField id={"statue"}
                                                label={"Status"} isMandatory={true}
                                                showMandatory={this.state.postInitiated}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                options={this.state.statusType || []}
                                                multiple={false} width={'100%'}
                                                value={this.state.projectDetails?.project_status_id_selected || []}
                                                onChange={(e, newValue) =>
                                                    this.handleGeneralEdit('project_status_id', newValue)
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md={4}
                                            id='genreDropDown'
                                            className={`${this.state.genreSelected?.length === 0 ? "searchSelectHeight genreSearchSelect" : "genreSearchSelect"}`}
                                        >
                                            <SearchSelectField id={"genre"}
                                                label={"Genre"} 
                                                options={this.state.genreType || []}
                                                multiple={true} width={'100%'}
                                                value={this.state.genreSelected}
                                                onChange={(e, newValue) =>
                                                    this.handleGeneralEdit('genres', newValue)
                                                }
                                            />
                                        </MDBCol>

                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md={9}>
                                            <MDBRow>
                                                <MDBCol md={3} className="col-content">
                                                    <MDBCol md={8} className="d-inline-block" id="dateWidth">
                                                        <DateField id={"prodStartDate"} label={'Prod. Start Date'}
                                                            value={this.state.projectDetails?.production_start_date_tbd === 0 ? getFormattedDate(this.state.projectDetails?.production_start_date, true) : null}
                                                            disabled={this.state.projectDetails?.production_start_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit('production_start_date', e.target.value)
                                                            } />
                                                    </MDBCol>
                                                    <MDBCol md={4} className="d-inline-block pl-15 tbd-checkbox" id="checkBox">
                                                        <BasicCheckbox
                                                            id={'production_start_date_tbd'}
                                                            label="TBD"
                                                            checked={this.state.projectDetails?.production_start_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                {
                                                                    this.handleGeneralEdit('production_start_date', null);
                                                                    this.handleGeneralEdit('production_start_date_tbd', e.target.checked);
                                                                }
                                                            }
                                                            align="start"
                                                        />
                                                    </MDBCol>
                                                </MDBCol>
                                                <MDBCol md={3} className="col-content">
                                                    <MDBCol md={8} className="d-inline-block" id="dateWidth">
                                                        <DateField id={"wrapDate"} label={'Wrap Date'}
                                                            value={this.state.projectDetails?.wrap_date_tbd === 0 ? getFormattedDate(this.state.projectDetails?.wrap_date, true) : null}
                                                            disabled={this.state.projectDetails?.wrap_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit('wrap_date', e.target.value)
                                                            } />
                                                    </MDBCol>
                                                    <MDBCol md={4} className="d-inline-block pl-15 tbd-checkbox" id="checkBox" >
                                                        <BasicCheckbox
                                                            id={'wrap_date_tbd'}
                                                            label="TBD"
                                                            checked={this.state.projectDetails?.wrap_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                {
                                                                    this.handleGeneralEdit('wrap_date', null)
                                                                    this.handleGeneralEdit('wrap_date_tbd', e.target.checked)
                                                                }
                                                            }
                                                            align="start"
                                                        />
                                                    </MDBCol>

                                                </MDBCol>
                                                <MDBCol md={3} className="col-content">
                                                    <MDBCol md={8} className="d-inline-block" id="dateWidth">
                                                        <DateField id={"releaseDate"} label={'Release Date'}
                                                            value={this.state.projectDetails?.release_date_tbd === 0 ? getFormattedDate(this.state.projectDetails?.release_date, true) : null}
                                                            disabled={this.state.projectDetails?.release_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit('release_date', e.target.value)
                                                            } />
                                                    </MDBCol>
                                                    <MDBCol md={4} className="d-inline-block pl-15 tbd-checkbox" id="checkBox">
                                                        <BasicCheckbox
                                                            id={'release_date_tbd'}
                                                            label="TBD"
                                                            checked={this.state.projectDetails?.release_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                {
                                                                    this.handleGeneralEdit('release_date', null)
                                                                    this.handleGeneralEdit('release_date_tbd', e.target.checked)
                                                                }
                                                            }
                                                            align="start"
                                                        />
                                                    </MDBCol>

                                                </MDBCol>
                                                <MDBCol md={3} className="col-content">
                                                    <MDBCol md={8} className="d-inline-block" id="dateWidth">
                                                        <DateField id={"rightsLapseDate"} label={'Rights Lapse Date'}
                                                            value={this.state.projectDetails?.rights_lapse_date_tbd === 0 ? getFormattedDate(this.state.projectDetails?.rights_lapse_date, true) : null}
                                                            disabled={this.state.projectDetails?.rights_lapse_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                this.handleGeneralEdit('rights_lapse_date', e.target.value)
                                                            } />
                                                    </MDBCol>
                                                    <MDBCol md={4} className="d-inline-block pl-15 tbd-checkbox" id="checkBox">
                                                        <BasicCheckbox
                                                            id={'rights_lapse_date_tbd'}
                                                            label="TBD"
                                                            checked={this.state.projectDetails?.rights_lapse_date_tbd === 1 ? true : false}
                                                            onChange={(e) =>
                                                                {
                                                                    this.handleGeneralEdit('rights_lapse_date', null)
                                                                    this.handleGeneralEdit('rights_lapse_date_tbd', e.target.checked)
                                                                }
                                                            }
                                                            align="start"
                                                        />
                                                    </MDBCol>

                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            <MDBRow className="DateControl">
                                                <MDBCol md={12} className="col-content">
                                                    <SearchSelectField id={"distributionCompany"}
                                                        label={"Distribution Company"}
                                                        options={this.state.distributionCompany || []}
                                                        multiple={false} width={'100%'}
                                                        value={this.state.projectDetails?.distribution_company_id_selected || []}
                                                        onChange={(e, newValue) =>
                                                            this.handleGeneralEdit('distribution_company_id', newValue)
                                                        }
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md={3}>
                                            <BasicTextField
                                                label="Material Location"
                                                limit={sizeLimits.nameCharacterLimit}
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                                onChange={(e) => {
                                                    this.handleGeneralEdit('material_location', e.target.value)
                                                }}
                                                value={this.state.projectDetails?.material_location || ""} />
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            <BasicTextField
                                                label="MPM#"
                                                limit={sizeLimits.nameCharacterLimit}
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                                onChange={(e) => {
                                                    this.handleGeneralEdit('mpm_no', e.target.value)
                                                }}
                                                value={this.state.projectDetails?.mpm_no || ""} />
                                        </MDBCol>

                                    </MDBRow>
                                    {this.props.mode != "New" ? <MDBRow>
                                        <MDBCol md={12}>
                                            <BasicTextArea
                                                label="Vault Information"
                                                className={"textArea"}
                                                rows={3}
                                                // limit={sizeLimits.nameCharacterLimit}
                                                // limitWarning={messages.exceed50CharacterWarning || ""}
                                                onChange={(e) => {
                                                    this.handleGeneralEdit('vault_information', e.target.value)
                                                }}
                                                value={this.state.projectDetails?.vault_information || ""} 
                                                // typedCharCount={this.state.projectDetails?.vault_information?.length || 0}
                                                // showCharCount={true}
                                            />
                                        </MDBCol>
                                    </MDBRow> : null}
                                    <MDBRow>
                                        <MDBCol md={12}>
                                            <BasicTextArea
                                                value={this.state.projectDetails?.project_notes || null}
                                                label={"Project Notes"}
                                                className={"textArea"}
                                                rows={3}
                                                limit={sizeLimits.largeNotes}
                                                limitWarning={messages.exceed1000CharacterWarning || ""}
                                                onChange={(e) =>
                                                    this.handleGeneralEdit('project_notes', e.target.value.length > 0 ? e.target.value : null)
                                                }
                                                typedCharCount={this.state.projectDetails?.project_notes?.length || 0}
                                                showCharCount={true}
                                            />
                                        </MDBCol>

                                    </MDBRow>
                                    <span className="project-subheading">Source Material</span>

                                    <MDBRow>
                                        {
                                            this.state.projectDetails?.source_material?.map((source_material_list, index) => (
                                                (source_material_list.is_delete === 0 ?
                                                    <>
                                                        <MDBCol md={11}>
                                                            <MDBRow>
                                                                <MDBCol md={4}>
                                                                    <SearchSelectField id={"type"}
                                                                        label={"Type"} 
                                                                        options={this.state.sourceType || []}
                                                                        multiple={false} width={'100%'}
                                                                        value={source_material_list?.type_id_selected || []}
                                                                        onChange={(e, newValue) =>
                                                                            this.handleListEdit('source_material', 'type_id', newValue, index)
                                                                        }
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label="Title"                                                                       
                                                                        limit={sizeLimits.largeNameCharLimit}                                                                       
                                                                        limitWarning={messages.exceed300CharacterWarning || ""}
                                                                        onChange={(e) => {
                                                                            this.handleListEdit('source_material', 'title', e.target.value.length > 0 ? e.target.value : null, index)
                                                                        }}
                                                                        value={source_material_list?.title || ""} />
                                                                </MDBCol>
                                                                <MDBCol md={4}>
                                                                    {/* <SearchSelectField
                                                                        id={"talent-select"}
                                                                        textFieldId={"talent-search"}
                                                                        label={"Author"}
                                                                        options={this.state.fieldOptions} width={'100%'}
                                                                        multiple={false}
                                                                        detail_selected={source_material_list?.author_selected}
                                                                        valueSelected={this.selectedAuthor(index)}
                                                                        searchText={this.getTalentDetails('author')}
                                                                        loading={this.state.authorLoading}
                                                                        searchSelect={true}
                                                                    /> */}
                                                                    <BasicTextField
                                                                        label="Author"                                                                       
                                                                        limit={sizeLimits.largeNameCharLimit}                                                                       
                                                                        limitWarning={messages.exceed300CharacterWarning || ""}
                                                                        onChange={(e) => {
                                                                            this.handleListEdit('source_material', 'author', e.target.value.length > 0 ? e.target.value : null, index)
                                                                        }}
                                                                        value={source_material_list?.author || ""} />
                                                                </MDBCol>
                                                            </MDBRow>
                                                            <MDBRow>
                                                                <MDBCol md={4}></MDBCol>
                                                                <MDBCol md={4}>
                                                                    <BasicTextField
                                                                        label="Publisher"
                                                                        limit={sizeLimits.largeNameCharLimit}
                                                                        limitWarning={messages.exceed300CharacterWarning || ""}
                                                                        onChange={(e) => {
                                                                            this.handleListEdit('source_material', 'publisher', e.target.value.length > 0 ? e.target.value : null, index)
                                                                        }}
                                                                        value={source_material_list?.publisher || ""} />
                                                                </MDBCol>
                                                                <MDBCol md={2} id="dateWidth">
                                                                    <DateField id={"date"} label={'Date'}
                                                                        value={getFormattedDate(source_material_list?.date, true)}
                                                                        onChange={(e) =>
                                                                            this.handleListEdit('source_material', 'date', e.target.value.length > 0 ? e.target.value : null, index)
                                                                        } />
                                                                </MDBCol>
                                                                <MDBCol md={2} id="dateWidth">
                                                                    <DateField id={"copyrightDate"} label={'Copyright Date'}
                                                                        value={getFormattedDate(source_material_list?.copyright_date, true)}
                                                                        onChange={(e) =>
                                                                            this.handleListEdit('source_material', 'copyright_date', e.target.value.length > 0 ? e.target.value : null, index)
                                                                        } />
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                        <MDBCol md={1}>
                                                            <MDBRow className="actionButons">
                                                                {
                                                                    !(this.state.projectDetails?.source_material.length === 1) ?
                                                                        <MDBCol md={6} className="actions alignField">
                                                                            <MDBIcon icon="trash-alt" size='sm' onClick={(e) =>
                                                                                this.removeField('source_material', source_material_list, index)
                                                                            } />
                                                                        </MDBCol>
                                                                        : null}
                                                                {
                                                                    (this.state.projectDetails?.source_material.length - 1 === index && source_material_list.type_id
                                                                        && source_material_list.title?.length > 0 && source_material_list.publisher?.length > 0) ?
                                                                        <MDBCol md={6} className="actions alignField">
                                                                            <AddCircleIcon fontSize="small"
                                                                                onClick={(e) =>
                                                                                    this.addAdditionalField('source_material', index)
                                                                                } />
                                                                        </MDBCol>
                                                                        : null
                                                                }
                                                            </MDBRow>
                                                        </MDBCol>
                                                    </> : null)
                                            ))}
                                    </MDBRow>

                                    <span className="project-subheading">People</span>
                                    <MDBRow>
                                        <MDBCol md={6}>
                                            {
                                                this.state.projectDetails?.executives?.map((executive, index) => (
                                                    (executive.is_delete === 0 ? <>
                                                        <MDBCol md={10} className="col-content d-inline-block" id="executive">
                                                            <SearchSelectField
                                                                id={"executive-select"}
                                                                label={index === 0 ? "Executives" : ""}
                                                                options={this.state.executiveOptions} width={'100%'}
                                                                loading={this.state.execLoading}
                                                                multiple={false}
                                                                textFieldId={"executive-search"}
                                                                detail_selected={executive.executive_selected}
                                                                valueSelected={this.selectedExecutive(index)}
                                                                searchText={this.getExecutiveDetails}
                                                                searchSelect={true}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={2} className="actionButons2 d-inline-block">
                                                            <MDBRow>
                                                                <MDBCol md={6} className="d-flex actionExecutive justify-content-end">
                                                                    {
                                                                        // (!(this.state.projectDetails?.executives.length === 1)) ?
                                                                            <MDBIcon icon="trash-alt" size='sm' onClick={(e) =>
                                                                                this.removeField('executives', executive, index)
                                                                            } />
                                                                            // : null
                                                                    }
                                                                </MDBCol>
                                                                <MDBCol md={6} className="d-flex">
                                                                    {
                                                                        (executive.executive_name?.length > 0 && index === (this.state.projectDetails?.executives.length - 1)) ?
                                                                            <AddCircleIcon onClick={(e) =>
                                                                                this.addAdditionalField('executives', index)
                                                                            } /> : null
                                                                    }
                                                                </MDBCol>
                                                            </MDBRow>


                                                        </MDBCol>
                                                    </> : null)
                                                ))
                                            }
                                        </MDBCol>

                                        <MDBCol md={6}>
                                            {
                                                this.state.projectDetails?.talents?.map((talent, index) => (
                                                    (talent.is_delete === 0 ? <>
                                                        <MDBCol md={10} className="col-content d-inline-block" id="executive">
                                                            {/* <SearchSelectField
                                                                id={"talent-select"}
                                                                textFieldId={"talent-search"}
                                                                label={index === 0 ? "Talent" : ""}
                                                                options={this.state.fieldOptions} width={'100%'}
                                                                multiple={false}
                                                                detail_selected={talent.talent_selected}
                                                                valueSelected={this.selectedTalent(index)}
                                                                searchText={this.getTalentDetails('talent')}
                                                                loading={this.state.talentLoading}
                                                                searchSelect={true}
                                                            /> */}
                                                            <BasicLabel text={index === 0 ? "Talent" : ""} />
                                                            <SearchFieldWithAddValue
                                                                id={"talent-select"}
                                                                placeholder={'- Search -'}
                                                                options={this.state.fieldOptions || []}
                                                                detail_selected={talent.talent_selected}
                                                                width={'100%'}
                                                                // valueSelected={this.selectedTalent(index)}
                                                                valueSelected={this.handleTalentOnChange(index)}
                                                                searchText={this.getTalentDetails('talent')}
                                                                multiple={false}
                                                                searchSelect={true}
                                                                loading={this.state.talentLoading}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={2} className="actions d-inline-block">
                                                            <MDBRow id='actionBtn'>
                                                                <MDBCol md={6} className="d-flex justify-content-end">
                                                                    {
                                                                        // (!(this.state.projectDetails?.talents.length === 1)) ?
                                                                            <MDBIcon icon="trash-alt" size='sm' onClick={(e) =>
                                                                                this.removeField('talents', talent, index)
                                                                         }/> 
                                                                            // : null
                                                                    }
                                                                </MDBCol>
                                                                <MDBCol md={6} className="d-flex pl1">
                                                                    {
                                                                        (talent.talent_name?.length > 0 && index === (this.state.projectDetails?.talents.length - 1)) ?
                                                                            <AddCircleIcon onClick={(e) =>
                                                                                this.addAdditionalField('talents', index)
                                                                            } /> : null
                                                                    }
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                    </> : null)
                                                ))
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                        <MDBRow>
                                            <SubAttachedFiles
                                                mode={this.props?.mode}
                                                fileRef={this.fileRef}
                                                filePayload={{
                                                    ...filePayload,
                                                    projectId: this.props?.projectId,
                                                    type: "project",
                                                    module: "project",
                                                    subModule:"misc",
                                                }}
                                                toggleRefreshPage={() => this.props.toggleRefreshPage('project')}
                                                attachedModuleFiles={this.state.attachedModuleFiles}
                                                handleFileUpload={this.handleProjectFileUpload}
                                                handleFileDelete={this.handleFileDelete}
                                                handleFilePrivate={this.handleFilePrivate}
                                                id={"file-upload"}
                                                />
                                    </MDBRow>

                                    <MDBRow className="mt-2">
                                        <MDBCol md={9}>
                                        </MDBCol>
                                        <MDBCol md={1}>
                                            <BasicButton
                                                className="btnwidth"
                                                variant="contained"
                                                type="inline"
                                                text={this.state.isSubmit ? <CircularProgress color="inherit" size={18} /> :
                                                    this.props.mode === "New" ? "Create" : "Save"}
                                                onClick={() =>
                                                    this.handleSubmit()
                                                }
                                                disabled={false}
                                            />
                                        </MDBCol>
                                        <MDBCol md={1}>
                                            <BasicButton
                                                type="inline"
                                                variant="outlined"
                                                text={this.props?.secondaryButtonText}
                                                onClick={() => this.props.onClose()}
                                                disabled={false}
                                            />
                                        </MDBCol>


                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>)}
            </div>

        );
    }
}))
