export const scripterServiceBaseUrl = 'https://api.qa.scripter.warnerbros.com';

export const scripterServiceWSBaseUrl = 'wss://x3xdfdux1g.execute-api.us-west-2.amazonaws.com/qa';  

export const scripterSharedServiceBaseUrl = '';

export const OKTA_CLIENT_ID = "0oa19dat153NEG0hi0h8";

export const OKTA_ISSUER_URL = "https://tw.oktapreview.com/oauth2/default";

export const OKTA_REDIRECT_URL = window.location.origin + '/callback';

export const OKTA_URL = "https://tw.oktapreview.com";

export const IDLE_TIMEOUT = "60";

export const IDLE_WARN_TIMEOUT = "30";